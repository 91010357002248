// BlurPdfEditor.tsx
import { ChevronLeft, ChevronRight, Undo } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { jsPDF } from "jspdf";
import * as pdfjsLib from "pdfjs-dist";
import { useEffect, useState } from "react";
import { Image as KonvaImage, Layer, Rect, Stage } from "react-konva";
import Loader from "../Loader/Loader";
import StandartButton from "../StandartButton/StandartButton";
import s from "./BlurPdfEditor.module.css";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

type BlurPdfEditorProps = {
  pdfUrl: string;
  savePdf: (newFile: File) => Promise<void>;
};

const BlurPdfEditor = ({ pdfUrl, savePdf }: BlurPdfEditorProps) => {
  const [pdfPages, setPdfPages] = useState<HTMLImageElement[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [blurredPages, setBlurredPages] = useState<(HTMLImageElement | null)[]>(
    []
  );
  const [areas, setAreas] = useState<
    Array<{
      startPos: { x: number; y: number };
      endPos: { x: number; y: number };
    }>
  >([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentArea, setCurrentArea] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      setLoader(true);
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      const pages: HTMLImageElement[] = [];

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 2 });
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: ctx, viewport }).promise;
        const img = new Image();
        img.src = canvas.toDataURL("image/png");
        await new Promise((resolve) => (img.onload = resolve));
        pages.push(img);
      }

      setPdfPages(pages);
      setBlurredPages(Array(pages.length).fill(null));
      setLoader(false);
    };
    loadPdf();
  }, [pdfUrl]);

  const currentImage =
    blurredPages[currentPageIndex] || pdfPages[currentPageIndex];

  const getImageDimensions = () => {
    if (!currentImage) return { width: 0, height: 0 };
    const containerWidth = window.innerWidth * 0.8;
    const containerHeight = window.innerHeight * 0.8;
    const aspectRatio = currentImage.width / currentImage.height;

    if (containerWidth / containerHeight > aspectRatio) {
      return { width: containerHeight * aspectRatio, height: containerHeight };
    } else {
      return { width: containerWidth, height: containerWidth / aspectRatio };
    }
  };

  const { width: imageWidth, height: imageHeight } = getImageDimensions();

  const handleMouseDown = (e: any) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setCurrentArea({ x, y });
    setIsDrawing(true);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing || !currentArea) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    setAreas((prevAreas) => {
      const newArea = { startPos: currentArea, endPos: { x, y } };
      return [...prevAreas.slice(0, -1), newArea];
    });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    if (currentArea) {
      setAreas((prevAreas) => [
        ...prevAreas,
        { startPos: currentArea, endPos: currentArea },
      ]);
    }
    setCurrentArea(null);
  };

  const handleBlurArea = () => {
    if (!areas.length || !currentImage) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    canvas.width = currentImage.width;
    canvas.height = currentImage.height;

    ctx.drawImage(currentImage, 0, 0, canvas.width, canvas.height);

    areas.forEach((area) => {
      const scaleX = imageWidth / currentImage.width;
      const scaleY = imageHeight / currentImage.height;
      const blurX = Math.min(area.startPos.x, area.endPos.x) / scaleX;
      const blurY = Math.min(area.startPos.y, area.endPos.y) / scaleY;
      const blurWidth = Math.abs(area.endPos.x - area.startPos.x) / scaleX;
      const blurHeight = Math.abs(area.endPos.y - area.startPos.y) / scaleY;

      ctx.filter = "blur(5px)";
      const pixelSize = 10;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(
        canvas,
        blurX,
        blurY,
        blurWidth,
        blurHeight,
        blurX,
        blurY,
        blurWidth / pixelSize,
        blurHeight / pixelSize
      );
      ctx.drawImage(
        canvas,
        blurX,
        blurY,
        blurWidth / pixelSize,
        blurHeight / pixelSize,
        blurX,
        blurY,
        blurWidth,
        blurHeight
      );
    });

    ctx.filter = "none";
    const newImg = new Image();
    newImg.src = canvas.toDataURL();
    newImg.onload = () => {
      setBlurredPages((prev) => {
        const updated = [...prev];
        updated[currentPageIndex] = newImg;
        return updated;
      });
      setAreas([]);
    };
  };

  const handleReset = () => {
    setAreas([]);
    setBlurredPages((prev) => {
      const updated = [...prev];
      updated[currentPageIndex] = null;
      return updated;
    });
  };

  const handleSave = async () => {
    setLoader(true);

    const finalPages = pdfPages.map(
      (page, index) => blurredPages[index] || page
    );
    const width = finalPages[0].width;
    const height = finalPages[0].height;
    const orientation = width > height ? "landscape" : "portrait";
    const doc = new jsPDF({ orientation: orientation });
    finalPages.forEach((page, index) => {
      if (index > 0) doc.addPage();
      doc.addImage(
        page,
        "PNG",
        0,
        0,
        doc.internal.pageSize.getWidth(),
        doc.internal.pageSize.getHeight()
      );
    });

    const pdfBlob = doc.output("blob");
    const pdfFile = new File([pdfBlob], "blurred-document.pdf", {
      type: "application/pdf",
    });
    await savePdf(pdfFile);
    setLoader(false);
  };

  const handleNextPage = () => {
    if (currentPageIndex < pdfPages.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
      setAreas([]);
    }
  };

  const handlePrevPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
      setAreas([]);
    }
  };

  if (loader || pdfPages.length === 0) {
    return (
      <div className={s.container} style={{ width: "80vh" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.toolsBlock}>
        <StandartButton
          action={handleBlurArea}
          buttonTitle="Blur"
          width="100px"
        />
        <StandartButton action={handleSave} buttonTitle="Save" width="100px" />

        <IconButton onClick={handleReset} sx={{ color: "white" }}>
          <Undo />
        </IconButton>
      </div>

      <Stage
        width={imageWidth}
        height={imageHeight}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          <KonvaImage
            image={currentImage}
            width={imageWidth}
            height={imageHeight}
          />
          {areas.map((area, index) => (
            <Rect
              key={index}
              x={Math.min(area.startPos.x, area.endPos.x)}
              y={Math.min(area.startPos.y, area.endPos.y)}
              width={Math.abs(area.endPos.x - area.startPos.x)}
              height={Math.abs(area.endPos.y - area.startPos.y)}
              stroke="red"
              strokeWidth={2}
            />
          ))}
        </Layer>
      </Stage>
      <div className={s.toolsBlock}>
        <IconButton onClick={handlePrevPage} sx={{ color: "white" }}>
          <ChevronLeft />
        </IconButton>
        {currentPageIndex + 1} of {pdfPages.length}
        <IconButton onClick={handleNextPage} sx={{ color: "white" }}>
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
};

export default BlurPdfEditor;
