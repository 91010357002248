import { Tooltip } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ProjectApi from "../../../../../../api/projectApi";
import UsersApi from "../../../../../../api/usersApi";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/Companies/delete.svg";
import CircleAvatar from "../../../../../../components/CircleAvatar/CircleAvatar";
import ConfirmationModal from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { ISO639Lang } from "../../../../../../constans/languagesList";
import { TypeCompanyEmployee } from "../../../../../../types/Company";
import { getTranslation } from "../../../../../../utils/getTranslation";
import { SuccesNotify } from "../../../../../../utils/toaster";
import s from "./SpecialistCardBlock.module.css";

type SpecialistCardBlockProps = {
  userId: string;
  bgColor: string;
  discount: number;
  projectId: string;
  setProjectSpecialists: Dispatch<SetStateAction<TypeCompanyEmployee[]>>;
  isInviteCard?: boolean;
  specialistsToInvite: string[];
  setSpecialistsToInvite: Dispatch<SetStateAction<string[]>>;
  specializationsData?: {
    specialistId: string;
    specialization: string;
    disabled?: boolean;
  }[];
};

type ProgramUserData = {
  name: string;
  surname: string;
  price: { amount: number; language: string }[];
  specializationsData: {
    specialistId: string;
    specialization: string;
    disabled?: boolean;
  }[];
  email: string;
};

const SpecialistCardBlock = ({
  userId,
  bgColor,
  discount,
  projectId,
  setProjectSpecialists,
  isInviteCard,
  specialistsToInvite,
  setSpecialistsToInvite,
  specializationsData,
}: SpecialistCardBlockProps) => {
  const token = localStorage.getItem("token");
  const [specialistData, setSpecialistData] = useState<ProgramUserData | null>(
    null
  );
  const [actionLoaders, setActionLoaders] = useState({
    laodUserData: false,
    removeSpecLoader: false,
  });
  const [deleteSpecModal, setDeleteSpecModal] = useState(false);
  const [selectSpecialist, setSelectSpecialist] = useState<{
    toAdd: string | undefined;
    toRemove: string | undefined;
  }>({
    toAdd: undefined,
    toRemove: undefined,
  });
  const [sessionPrice, setSessionPrice] = useState<
    | {
        priceWithDiscount: string;
        generalPrice: string;
        language: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    if (selectSpecialist.toRemove) {
      setSpecialistsToInvite((prev) =>
        prev.filter((el) => el !== selectSpecialist.toRemove)
      );
    }
    if (selectSpecialist.toAdd) {
      setSpecialistsToInvite((prev) => [...prev, selectSpecialist.toAdd!]);
    }
  }, [selectSpecialist]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const selectUserFields = "name surname email specialistIds";
      setActionLoaders((prev) => ({ ...prev, laodUserData: true }));

      const response = await UsersApi.getUserSelectedFields(
        token,
        userId,
        selectUserFields,
        "price mainSpecializations servicesConfig"
      );

      if (
        response.status &&
        response.userData &&
        response.userData.specialistIds
      ) {
        const responseUserData = {
          name: getTranslation(response.userData.name!),
          surname: getTranslation(response.userData.surname!),

          specializationsData: specializationsData
            ? specializationsData
            : response.userData.specialistIds.map((item) => ({
                specialization: item.mainSpecializations[0].specialization,
                specialistId: item._id,
                disabled: !item.servicesConfig.showInProjects.switch,
              })),
          price: response.userData.specialistIds[0].price!,
          email: response.userData.email!,
        };
        setSpecialistData(responseUserData);
      }
      setActionLoaders((prev) => ({ ...prev, laodUserData: false }));
    };
    makeAsync();
  }, [token]);

  const removeSpecFromProject = async () => {
    if (!token) return;
    const payload = { userIdToRemove: userId, projectId: projectId };
    setActionLoaders((prev) => ({ ...prev, removeSpecLoader: true }));
    const response = await ProjectApi.removeUserFromProject(token, payload);
    setActionLoaders((prev) => ({ ...prev, removeSpecLoader: false }));
    if (response.status) {
      setDeleteSpecModal(false);
      SuccesNotify("Specialist has been removed from project");
      setProjectSpecialists((prev) =>
        prev.filter((spec) => spec.user._id !== userId)
      );
    }
  };

  useEffect(() => {
    if (!specialistData || !specialistData.price) return;
    const prices = specialistData.price;

    const pricesData = prices.map((item) => ({
      priceWithDiscount:
        discount === 100
          ? "0"
          : discount === 0
          ? item.amount.toString()
          : (item.amount - item.amount * (discount / 100)).toFixed(0),
      generalPrice: item.amount.toString(),
      language: item.language,
    }));

    setSessionPrice(pricesData);
  }, [discount, specialistData]);
  return (
    <div className={s.container} style={{ backgroundColor: bgColor }}>
      <div className={s.mainSpecInfoBlock}>
        <CircleAvatar userId={userId} width={"50px"} height={"50px"} />
        <div className={s.mainSpecTitleInfo}>
          <span>
            {specialistData &&
              specialistData.name + " " + specialistData.surname}
          </span>
        </div>{" "}
      </div>
      <div className={s.mainSpecInfoBlock}>
        {specialistData && specialistData.email}
      </div>
      <div className={s.specalizationTitleBlock}>
        {specialistData &&
          specialistData?.specializationsData.map((item, index) => (
            <Tooltip
              arrow
              title={
                <span
                  style={{
                    fontSize: "16px",
                  }}
                >
                  This specialist did not enable the 'Participation in projects'
                  service
                </span>
              }
              disableHoverListener={!item.disabled}
              key={index}
            >
              <div
                style={{ opacity: item.disabled ? 0.5 : 1 }}
                className={
                  specialistsToInvite.includes(item.specialistId) &&
                  isInviteCard
                    ? s.activeSpecialization
                    : s.notActiveSpecialiation
                }
                onClick={() =>
                  isInviteCard && !item.disabled
                    ? setSelectSpecialist((prev) => ({
                        ...prev,
                        toAdd:
                          prev.toAdd === item.specialistId
                            ? undefined
                            : item.specialistId,
                        toRemove:
                          prev.toAdd === item.specialistId ||
                          (prev.toAdd && prev.toAdd !== item.specialistId)
                            ? prev.toAdd
                            : undefined,
                      }))
                    : undefined
                }
              >
                {item.specialization}
              </div>
            </Tooltip>
          ))}
      </div>

      {specialistData && (
        <div className={s.priceActionBlock}>
          {isInviteCard && <div> </div>}
          <div className={s.prcieBlock}>
            {sessionPrice &&
              sessionPrice.map((item, index) => (
                <div className={s.priceLement} key={index}>
                  <span>{ISO639Lang[item.language]}</span>
                  <span>-</span>
                  <div className={s.priceValue}>
                    {discount > 0 && <span>{item.generalPrice}$</span>}
                    <span>{item.priceWithDiscount}$</span>
                  </div>
                </div>
              ))}
          </div>
          {!isInviteCard && (
            <div className={s.actionBlock}>
              <div
                className={s.deleteBtn}
                role="button"
                onClick={() => setDeleteSpecModal(true)}
              >
                <DeleteIcon className={s.deleteIcon} />
              </div>
            </div>
          )}
        </div>
      )}
      {specialistData &&
        specialistsToInvite.some(
          (item) =>
            specialistData.specializationsData
              .map((item) => item.specialistId)
              .includes(item) && isInviteCard
        ) && <div className={s.indicator}></div>}
      <ConfirmationModal
        confirmFunc={removeSpecFromProject}
        confirmTitle={`Are you sure you want to remove ${
          specialistData?.name + " " + specialistData?.surname
        } from this project?`}
        modalVisible={deleteSpecModal}
        setModalVisible={setDeleteSpecModal}
        loader={actionLoaders.removeSpecLoader}
      />
    </div>
  );
};

export default SpecialistCardBlock;
