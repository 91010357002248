import { Radio } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SpecialistApi from "../../../../../api/specialistsApi";
import { ReactComponent as EditIcon } from "../../../../../assets/General/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/General/plus.svg";
import Input from "../../../../../components/Input/Input";
import Loader from "../../../../../components/Loader/Loader";
import MuiDatePicker from "../../../../../components/MuiDatePicker/MuiDatePicker";
import MultipleSelect from "../../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import {
  documentExtensions,
  imageExtensions,
} from "../../../../../constans/filesExtensions";
import { ContextProvider } from "../../../../../contextProvider";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo } from "../../../../../types/TypeUsers";
import { compressFiles } from "../../../../../utils/compressionFile";
import { getTranslation } from "../../../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../../../utils/toaster";
import s from "../UpdateCertificatesModal.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type FederationsCertificates = {
  selectData: {
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  };
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  specialistId: string;
  specialization: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
  subSpecData: {
    value: string;
    lable: string;
  }[];
  actionLoaders: {
    addedCertificate: boolean;
  };
  setShowSendReviewRequestBtn?: Dispatch<SetStateAction<boolean>>;
  educationToUpdate?: EducationCertificatesResponse;
};

type NewEducation = {
  newEducation: {
    educationText: string | undefined;
    courseText: string | undefined;
    accreditationText: string | undefined;
  };
  educationToSave: {
    file: File | undefined;
    educationCourseId: string | undefined;
    educationAccreditationId?: string;
    educationId: string | undefined;
    membershipStart: Date | undefined;
    membershipEnd: Date | undefined;
    finished: boolean;
    studyHours: number | undefined;
    subSpecializations: string[] | undefined;
  };
};

const initialEducation: NewEducation = {
  newEducation: {
    educationText: undefined,
    courseText: undefined,
    accreditationText: undefined,
  },
  educationToSave: {
    file: undefined,
    educationCourseId: undefined,
    educationAccreditationId: undefined,
    educationId: undefined,
    membershipStart: undefined,
    membershipEnd: undefined,
    finished: true,
    studyHours: undefined,
    subSpecializations: undefined,
  },
};

const EducationsCertificates = ({
  selectData,
  setActionLoaders,
  specialistId,
  specialization,
  setModalOpen,
  setNewCertificate,
  subSpecData,
  actionLoaders,
  setShowSendReviewRequestBtn,
  educationToUpdate,
}: FederationsCertificates) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [selectEducation, setSelectEducation] = useState<string>("");
  const [selectCourse, setSelectCourse] = useState<string>("");
  const [selectAccreditation, setSelectAccreditation] = useState<string>("");
  const [addEducation, setAddEducation] =
    useState<NewEducation>(initialEducation);
  const [coursesData, setCoursesData] = useState<
    { value: string; lable: string }[]
  >([]);
  const [accreditationsData, setAccreditationsData] = useState<
    { value: string; lable: string }[]
  >([]);

  const studyFinishedData = [
    { lable: "Yes", value: true },
    { lable: "No", value: false },
  ];

  useEffect(() => {
    if (!educationToUpdate) return;

    setSelectEducation(educationToUpdate.education._id);
    setSelectCourse(educationToUpdate.educationCourse._id);
    setSelectAccreditation(
      educationToUpdate.educationAccreditation?._id ?? "notAccredited"
    );
    setAddEducation((prev) => ({
      ...prev,
      educationToSave: {
        ...prev.educationToSave,
        file: educationToUpdate.filename
          ? ({ name: educationToUpdate.filename } as File)
          : undefined,
        finished: educationToUpdate.finished!,
      },
    }));
  }, [educationToUpdate]);
  const handleCertificateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file && file.type.includes("pdf")) {
        setAddEducation((prev) => ({
          ...prev,
          educationToSave: {
            ...prev.educationToSave,
            file: file,
          },
        }));
      }
      if (file && !file.type.includes("pdf")) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddEducation((prev) => ({
            ...prev,
            educationToSave: {
              ...prev.educationToSave,
              file: compressedFile.file,
            },
          }));
        }
      }
    }
  };

  const createEducation = async () => {
    if (!token || !userData) return;
    const payload = {
      educationText: [
        {
          text: addEducation.newEducation.educationText!,
          language: userData.selectedLanguage!,
        },
      ],
      specialization: specialization,
    };
    const response = await SpecialistApi.createEducation(token, payload);

    return { status: response.status, educationId: response.education?._id };
  };

  const createEducationCourse = async (educationId: string) => {
    if (!token || !userData) return;
    const payload = {
      courseText: [
        {
          text: addEducation.newEducation.courseText!,
          language: userData.selectedLanguage!,
        },
      ],
      educationId: educationId,
    };
    const response = await SpecialistApi.createEducationCourse(token, payload);
    if (response.status && response.course) {
      return response.course._id;
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return undefined;
  };
  const createEducationAccreditation = async (courseId: string) => {
    if (!token || !userData) return;
    const payload = {
      accreditationText: [
        {
          text: addEducation.newEducation.accreditationText!,
          language: userData.selectedLanguage!,
        },
      ],
      courseId: courseId,
    };
    const response = await SpecialistApi.createEducationAccreditation(
      token,
      payload
    );

    if (response.status && response.accreditation) {
      return response.accreditation._id;
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return undefined;
  };

  const validationNewCertificate = () => {
    const educationToSave = addEducation.educationToSave;
    const newEducation = addEducation.newEducation;
    if (!selectEducation || !selectCourse) return false;
    if (
      (!educationToUpdate &&
        educationToSave.membershipStart &&
        !dayjs(educationToSave.membershipStart).isValid()) ||
      (!educationToUpdate &&
        educationToSave.membershipEnd &&
        !dayjs(educationToSave.membershipEnd).isValid()) ||
      (!educationToUpdate && !educationToSave.studyHours) ||
      (!educationToUpdate && educationToSave.finished && !educationToSave.file)
    )
      return false;
    if (
      (selectEducation === "other" && !newEducation.educationText) ||
      (selectAccreditation === "other" && !newEducation.accreditationText) ||
      (selectCourse === "other" && !newEducation.courseText)
    )
      return false;
    return true;
  };

  const removeUndefined = (obj: Record<string, any>) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined)
    );
  };

  const updateEducation = async () => {
    if (!token || !educationToUpdate) return;
    try {
      setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
      let educationId: string | undefined = undefined;
      let courseId: string | undefined = undefined;
      let accreditationId: string | undefined | null = undefined;
      if (selectEducation === "other") {
        const createEducationResponse = await createEducation();
        educationId = createEducationResponse?.educationId;
      }
      if (selectEducation !== "other") {
        educationId = selectEducation;
      }
      if (
        (selectCourse === "other" || selectEducation === "other") &&
        educationId
      ) {
        courseId = await createEducationCourse(educationId);
      }
      if (selectCourse !== "other" && selectEducation !== "other") {
        courseId = selectCourse;
      }
      if (
        (selectCourse === "other" ||
          selectEducation === "other" ||
          selectAccreditation === "other") &&
        courseId &&
        addEducation.newEducation.accreditationText &&
        addEducation.newEducation.accreditationText.length
      ) {
        accreditationId = await createEducationAccreditation(courseId);
      }
      if (
        selectCourse !== "other" &&
        selectEducation !== "other" &&
        selectAccreditation !== "other" &&
        selectAccreditation.length &&
        selectAccreditation !== "notAccredited"
      ) {
        accreditationId = selectAccreditation;
      }
      if (selectAccreditation === "notAccredited") {
        accreditationId = null;
      }
      if (educationId && courseId) {
        console.log(accreditationId);

        const updateData = {
          ...addEducation.educationToSave,
          _id: educationToUpdate._id,
          education:
            educationToUpdate.education._id === educationId
              ? undefined
              : educationId,
          educationCourse:
            educationToUpdate.educationCourse._id === courseId
              ? undefined
              : courseId,
          educationAccreditation:
            educationToUpdate.educationAccreditation &&
            educationToUpdate.educationAccreditation._id === accreditationId
              ? undefined
              : accreditationId,
        };

        delete updateData.file;
        const payload = {
          file: addEducation.educationToSave.file?.size
            ? addEducation.educationToSave.file
            : undefined,
          specialistId: specialistId,
          updateData: removeUndefined(updateData) as TypeUpdatedUserInfo,
        };

        const mainResponse = await SpecialistApi.updateEducationCertificate(
          payload,
          token
        );

        if (mainResponse.status && mainResponse.certificate) {
          setShowSendReviewRequestBtn && setShowSendReviewRequestBtn(true);
          setNewCertificate((prev) => ({
            ...prev,
            education: prev.education
              ? prev.education.map((item) => {
                  if (item._id === educationToUpdate._id) {
                    return mainResponse.certificate!;
                  }
                  return item;
                })
              : [mainResponse.certificate!],
          }));
          SuccesNotify("Certificate has been updated");
          setAddEducation(initialEducation);
          setModalOpen(false);
          setSelectEducation("");
          setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
        }
        if (!mainResponse.status && mainResponse.message) {
          Notify(mainResponse.message);
          setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !selectEducation ||
        !selectEducation.length ||
        selectEducation === "other"
      )
        return;
      const response = await SpecialistApi.getEducationCourses(
        selectEducation,
        userData?.selectedLanguage
      );
      if (response.status && response.courses) {
        setCoursesData(
          response.courses.map((item) => ({
            value: item._id,
            lable: item.text.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
        );
      }
    })();
  }, [selectEducation]);
  useEffect(() => {
    if (
      (educationToUpdate &&
        selectEducation === educationToUpdate.education._id) ||
      !selectEducation
    )
      return;
    setAccreditationsData([]);
    setSelectAccreditation("");
    setSelectCourse("");
  }, [selectEducation]);

  useEffect(() => {
    (async () => {
      if (!selectCourse.length || selectCourse === "other") {
        setAccreditationsData([]);
        if (
          (!educationToUpdate ||
            educationToUpdate.educationCourse._id !== selectCourse) &&
          selectCourse
        ) {
          setSelectAccreditation("");
        }
        return;
      }
      if (
        (!educationToUpdate ||
          educationToUpdate.educationCourse._id !== selectCourse) &&
        selectCourse
      ) {
        setSelectAccreditation("");
      }
      const response = await SpecialistApi.getEducationAccreditations(
        selectCourse,
        userData?.selectedLanguage
      );
      if (response.status && response.accreditations) {
        setAccreditationsData(
          response.accreditations.map((item) => ({
            value: item._id,
            lable: item.text.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
        );
      }
    })();
  }, [selectCourse]);

  if (actionLoaders.addedCertificate) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      {selectData &&
        selectData.education &&
        (selectEducation !== "other" ? (
          <MultipleSelect
            data={[
              ...(selectData.education.map((item) => ({
                lable:
                  item.text.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? item.text[0].text,
                value: item._id,
              })) ?? []),
              ...(educationToUpdate &&
              !selectData.education
                .map((item) => item._id)
                .includes(educationToUpdate.education._id)
                ? [
                    {
                      lable: getTranslation(
                        educationToUpdate.education.text,
                        "en"
                      ),
                      value: educationToUpdate.education._id,
                    },
                  ]
                : []),
              { lable: "Other", value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectEducation(value as string)}
            value={selectEducation}
            padding="10px"
            margin="0 0 20px 0"
            lable={"Education title"}
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={addEducation.newEducation.educationText ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddEducation((prev) => ({
                  ...prev,
                  newEducation: {
                    ...prev.newEducation,
                    educationText: value,
                  },
                }))
              }
              lable={"Education title"}
              margin="0 0 20px 0"
              closeFunction={() => setSelectEducation("")}
            />
          </div>
        ))}
      {selectEducation === "other" || selectCourse === "other" ? (
        <Input
          inputValue={addEducation.newEducation.courseText ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              newEducation: {
                ...prev.newEducation,
                courseText: value,
              },
            }))
          }
          lable={"Course title"}
          margin="0 0 20px 0"
          closeFunction={() => setSelectEducation("")}
        />
      ) : (
        <MultipleSelect
          data={[
            ...coursesData,
            ...(educationToUpdate &&
            !coursesData
              .map((item) => item.value)
              .includes(educationToUpdate.educationCourse._id)
              ? [
                  {
                    lable: getTranslation(
                      educationToUpdate.educationCourse.text,
                      "en"
                    ),
                    value: educationToUpdate.educationCourse._id,
                  },
                ]
              : []),
            { lable: "Other", value: "other" },
          ]}
          multiplie={false}
          setValue={(value) => setSelectCourse(value as string)}
          value={selectCourse}
          lable={"Course title"}
          padding="10px"
          margin="0 0 20px 0"
          disabled={selectEducation.length ? false : true}
        />
      )}
      {selectEducation === "other" ||
      selectCourse === "other" ||
      selectAccreditation === "other" ? (
        <Input
          inputValue={addEducation.newEducation.accreditationText ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              newEducation: {
                ...prev.newEducation,
                accreditationText: value,
              },
            }))
          }
          lable={"Accreditation"}
          margin="0 0 20px 0"
          closeFunction={() => setSelectEducation("")}
        />
      ) : (
        <MultipleSelect
          data={[
            ...accreditationsData,
            ...(educationToUpdate &&
            educationToUpdate.educationAccreditation &&
            !accreditationsData
              .map((item) => item.value)
              .includes(educationToUpdate.educationAccreditation._id)
              ? [
                  {
                    lable: getTranslation(
                      educationToUpdate.educationAccreditation.text,
                      "en"
                    ),
                    value: educationToUpdate.educationAccreditation._id,
                  },
                ]
              : []),
            { lable: "Not Accredited", value: "notAccredited" },
            { lable: "Other", value: "other" },
          ]}
          multiplie={false}
          setValue={(value) => setSelectAccreditation(value as string)}
          value={selectAccreditation}
          lable={"Accreditation"}
          padding="10px"
          margin="0 0 20px 0"
          disabled={selectCourse.length ? false : true}
        />
      )}
      <div className={s.multiSelectBlock}>
        <MultipleSelect
          multiplie={true}
          data={subSpecData}
          setValue={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              educationToSave: {
                ...prev.educationToSave,
                subSpecializations: value as string[],
              },
            }))
          }
          value={
            addEducation.educationToSave.subSpecializations ??
            educationToUpdate?.subSpecializations.map((item) => item._id) ??
            []
          }
          lable={"Specialization"}
          checkBox
          chip
          padding="10px"
          margin="0 0 20px 0"
        />
      </div>
      <div>
        <span className={s.secondLable}>
          Have you finished your studies yet?
        </span>
        {studyFinishedData.map((item, index) => (
          <div
            key={index}
            className={s.selectFinishedStudyBlock}
            onClick={() =>
              setAddEducation((prev) => ({
                ...prev,
                educationToSave: {
                  ...prev.educationToSave,
                  finished: item.value,
                },
              }))
            }
          >
            <Radio
              checked={addEducation.educationToSave.finished === item.value}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "#3FB083",
                },
              }}
            />
            <span>{item.lable}</span>
          </div>
        ))}
      </div>
      <div>
        <span className={s.secondLable}>Total studying hours</span>
        <Input
          inputValue={
            addEducation.educationToSave.studyHours !== undefined
              ? addEducation.educationToSave.studyHours.toString()
              : educationToUpdate
              ? educationToUpdate.studyHours.toString()
              : ""
          }
          isVisible
          required
          type="number"
          onChangeInput={(value) => {
            const numericValue = value === "" ? 0 : Number(value);
            if (typeof numericValue === "number" && numericValue >= 0) {
              setAddEducation((prev) => ({
                ...prev,
                educationToSave: {
                  ...prev.educationToSave,
                  studyHours: numericValue,
                },
              }));
            }
          }}

          /*     enableValidateNumber={false} */
        />
      </div>
      <div className={s.dateBlock}>
        <span className={s.secondLable}>
          Enter the start date and end date of the study
        </span>
        <div className={s.dateInputBlock}>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addEducation.educationToSave.membershipStart
                  ? dayjs(addEducation.educationToSave.membershipStart)
                  : educationToUpdate &&
                    educationToUpdate.membershipStart &&
                    educationToUpdate.membershipStart.length > 0
                  ? dayjs(new Date(educationToUpdate!.membershipStart))
                  : undefined
              }
              setValue={(value: Dayjs | null) =>
                setAddEducation((prev) => ({
                  ...prev,
                  educationToSave: {
                    ...prev.educationToSave,
                    membershipStart: value?.toDate(),
                  },
                }))
              }
            />
          </div>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addEducation.educationToSave.membershipEnd
                  ? dayjs(addEducation.educationToSave.membershipEnd)
                  : educationToUpdate &&
                    educationToUpdate.membershipEnd &&
                    educationToUpdate.membershipEnd.length > 0
                  ? dayjs(new Date(educationToUpdate!.membershipEnd))
                  : undefined
              }
              setValue={(value: Dayjs | null) =>
                setAddEducation((prev) => ({
                  ...prev,
                  educationToSave: {
                    ...prev.educationToSave,
                    membershipEnd: value?.toDate(),
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      {addEducation.educationToSave.finished && (
        <div className={s.uploadCertificateBlock}>
          <span className={s.headLable}>Certificate</span>
          <span className={s.secondLable}>
            Add a certificate in image or pdf format
          </span>
          <div className={s.uploadFilesActionBlock}>
            {addEducation.educationToSave.file && (
              <div>{addEducation.educationToSave.file.name}</div>
            )}
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept={[...imageExtensions, ...documentExtensions].join(", ")}
                multiple={false}
                onChange={handleCertificateChange}
              />
              {addEducation.educationToSave.file ? (
                <span className={s.updateAvatarBtn} role="button">
                  <EditIcon />
                  <span>Replace</span>
                </span>
              ) : (
                <div className={s.addCertificateBtn}>
                  <PlusIcon />
                  <span>Add certificate</span>
                </div>
              )}
            </label>
          </div>
        </div>
      )}
      <StandartButton
        action={updateEducation}
        buttonTitle={"Save"}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default EducationsCertificates;
