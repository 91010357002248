import { Undo } from "@mui/icons-material"; // Иконки из MUI
import { IconButton } from "@mui/material"; // Используем Material UI для кнопок
import { useState } from "react";
import { Image as KonvaImage, Layer, Rect, Stage } from "react-konva";
import useImage from "use-image";
import Loader from "../Loader/Loader";
import StandartButton from "../StandartButton/StandartButton";
import s from "./BlurImageEditor.module.css";

type BlurImageEditorProps = {
  imageUrl: string;
  saveImage: (newFile: File) => Promise<void>;
};

const BlurImageEditor = ({ imageUrl, saveImage }: BlurImageEditorProps) => {
  const [image] = useImage(imageUrl, "anonymous");
  const [blurredImage, setBlurredImage] = useState<HTMLImageElement | null>(
    null
  );
  const [areas, setAreas] = useState<
    Array<{
      startPos: { x: number; y: number };
      endPos: { x: number; y: number };
    }>
  >([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentArea, setCurrentArea] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [scale, setScale] = useState(1);
  const [loaders, setLoaders] = useState({
    mainLoader: false,
    blurLoader: false,
  });

  const getImageDimensions = () => {
    if (!image) return { width: 0, height: 0 };

    const containerWidth = window.innerWidth * 0.8;
    const containerHeight = window.innerHeight * 0.8;

    const aspectRatio = image.width / image.height;

    if (containerWidth / containerHeight > aspectRatio) {
      return {
        width: containerHeight * aspectRatio,
        height: containerHeight,
      };
    } else {
      return {
        width: containerWidth,
        height: containerWidth / aspectRatio,
      };
    }
  };

  const { width: imageWidth, height: imageHeight } = getImageDimensions();

  const handleMouseDown = (e: any) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setCurrentArea({ x, y });
    setIsDrawing(true);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing || !currentArea) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    setAreas((prevAreas) => {
      const newArea = {
        startPos: currentArea,
        endPos: { x, y },
      };
      return [...prevAreas.slice(0, prevAreas.length - 1), newArea]; // Обновляем только последнюю область
    });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    if (currentArea) {
      setAreas((prevAreas) => {
        const newArea = {
          startPos: currentArea,
          endPos: { x: currentArea.x, y: currentArea.y },
        };
        return [...prevAreas, newArea]; // Добавляем новую область
      });
    }
    setCurrentArea(null);
  };

  const handleBlurArea = () => {
    if (!areas.length || !image || loaders.blurLoader) return;

    setLoaders((prev) => ({ ...prev, blurLoader: true }));

    const worker = new Worker(new URL("./blurWorker.js", import.meta.url));
    worker.postMessage(
      {
        imageSrc: image.src,
        areas,
        width: image.width,
        height: image.height,
        displayWidth: imageWidth,
        displayHeight: imageHeight,
      },
      []
    );

    worker.onmessage = (e) => {
      const { blurredImageBitmap } = e.data;
      const newImg = new Image();
      createImageBitmap(blurredImageBitmap).then((bitmap) => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext("2d")!;
        ctx.drawImage(bitmap, 0, 0);
        newImg.src = canvas.toDataURL();
        newImg.onload = () => {
          setBlurredImage(newImg);
          setLoaders((prev) => ({ ...prev, blurLoader: false }));
        };
      });
      worker.terminate();
    };

    worker.onerror = (error) => {
      console.error("Worker error:", error);
      setLoaders((prev) => ({ ...prev, blurLoader: false }));
    };
  };

  const handleReset = () => {
    setAreas([]);
    setBlurredImage(null);
    setScale(1); // Сброс масштаба
  };

  const imageToFile = (
    image: HTMLImageElement,
    fileName: string = "image.png"
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) return reject(new Error("Canvas context не получен"));

      // Рисуем изображение на canvas
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      // Преобразуем canvas в Blob и создаем File
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        } else {
          reject(new Error("Ошибка при создании Blob"));
        }
      }, "image/png");
    });
  };

  const handleSave = async () => {
    if (!blurredImage) return;

    try {
      setLoaders((prev) => ({ ...prev, mainLoader: true }));

      const file = await imageToFile(blurredImage, "blurred-image.png");
      await saveImage(file);
    } catch (err) {
      console.error("Ошибка сохранения:", err);
    } finally {
      setLoaders((prev) => ({ ...prev, mainLoader: false }));
    }
  };

  if (!image || loaders.mainLoader) {
    return (
      <div className={s.container} style={{ width: "80vh" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.tollsBlock}>
        {/* Панель инструментов */}
        {loaders.blurLoader ? (
          <div
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader size={42} />
          </div>
        ) : (
          <StandartButton
            action={handleBlurArea}
            buttonTitle="Blur"
            width="100px"
          />
        )}
        <StandartButton action={handleSave} buttonTitle="Save" width="100px" />

        <IconButton onClick={handleReset} sx={{ color: "white" }}>
          <Undo />
        </IconButton>
      </div>

      <Stage
        width={imageWidth}
        height={imageHeight}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          <KonvaImage
            image={blurredImage || image}
            width={imageWidth * scale}
            height={imageHeight * scale}
          />
          {areas.map((area, index) => (
            <Rect
              key={index}
              x={Math.min(area.startPos.x, area.endPos.x)}
              y={Math.min(area.startPos.y, area.endPos.y)}
              width={Math.abs(area.endPos.x - area.startPos.x)}
              height={Math.abs(area.endPos.y - area.startPos.y)}
              stroke="red"
              strokeWidth={2}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default BlurImageEditor;
