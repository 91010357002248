import { CircularProgress, Skeleton, ThemeProvider } from "@mui/material";
import countries from "i18n-iso-countries";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ReactSpoiler } from "react-simple-spoiler";
import "swiper/css";
import "swiper/css/pagination";
import SpecialistApi from "../../api/specialistsApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as ChevronDown } from "../../assets/Profile/specialist/chevron-down.svg";
import { ReactComponent as SpecialistyReviewsIcon } from "../../assets/Profile/specialist/comments.svg";
import { ReactComponent as EearthIcon } from "../../assets/Profile/specialist/earh.svg";
import { ReactComponent as SpecializationIcon } from "../../assets/Profile/specialist/global.svg";
import { ReactComponent as GreenStar } from "../../assets/Profile/specialist/green-star.svg";
import { ReactComponent as MessageIcon } from "../../assets/Profile/specialist/message.svg";
import { ReactComponent as PlayBtnIcon } from "../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as SpecialitySessionIcon } from "../../assets/Profile/specialist/sessions.svg";
import { ReactComponent as TotalEducationIcon } from "../../assets/Profile/specialist/total-education.svg";
import { ReactComponent as TotalFederationsIcon } from "../../assets/Profile/specialist/total-federations.svg";
import { ReactComponent as TotalQualificationsIcon } from "../../assets/Profile/specialist/total-qualifications.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/Profile/specialist/verify.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import PackageCard from "../../components/PackageCard/PackageCard";
import { network } from "../../config";
import { ISO639LangReversed } from "../../constans/languagesList";
import { SessionComment, SessionPackage } from "../../types/TypeSession";
import { SpecialistData, VideoMetadata } from "../../types/TypeSpecialist";
import { TypeUserData } from "../../types/TypeUsers";
import url from "../../utils/formApiUrl";
import { getTranslation } from "../../utils/getTranslation";
import { calculateAge } from "../../utils/setupProfileInfo";
import { theme } from "../../utils/theme";
import Notify from "../../utils/toaster";
import EducationSection from "./EducationSection/EducationSection";
import s from "./Profile.module.css";
import ReviewsSection from "./ReviewsSection/ReviewsSection";
import SessionPrices from "./SessionPrices/SessionPrices";
import ShowDiff from "./ShowDiff/ShowDiff";

const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;

const { specialists } = network;

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export type CertificatesData = {
  _id: string;
  filename: string;
  type: string;
  size: number;
  link: string;
};

type ProfileProps = {
  userId: string;
  isOpen: boolean;
  getPublicInfoString: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPublicProfile: boolean;
  initialSpecialization?: string;
  showDiff?: boolean;
};

const Profile = ({
  userId,
  getPublicInfoString,
  isOpen,
  setIsOpen,
  isPublicProfile,
  initialSpecialization,
  showDiff,
}: ProfileProps) => {
  const token = localStorage.getItem("token");
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [loaders, setLoaders] = useState({
    mainLoader: false,
    certificates: false,
  });
  const [profileUserData, setProfileUserData] = useState<
    (TypeUserData & { specialistId?: SpecialistData }) | undefined | null
  >();

  const [commentsData, setCommentsData] = useState<SessionComment[]>([]);

  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});
  const [actionLoaders, setActionLoaders] = useState({
    certificates: false,
    packages: false,
  });
  const [packagesData, setPackagesData] = useState<{
    packagesData: SessionPackage[];
    overlaps: {
      day: string;
      overlapTime: {
        start: Date;
        end: Date;
      }[];
    }[];
  } | null>(null);

  const [specialistData, setSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }[]
    | undefined
  >();
  const [selectedSpecialistData, setSelectedSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }
    | undefined
  >();
  const [textHeight, setTextHeight] = useState({
    aboutMyself: 0,
    aboutTeraphy: 0,
  });
  const [videoPreviewImg, setVideoPreviewImg] = useState<{
    imgSrc: string | undefined;
    loader: boolean;
  }>({ imgSrc: undefined, loader: true });
  const [currentSpecialistTotalInfo, setCurrentSpecialistTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});
  const [profileChanges, setProfileChanges] = useState<{
    userChanges: string[];
    specialistChanges: Record<string, string[]>;
  }>({
    userChanges: [],
    specialistChanges: {},
  });
  const [profilesData, setProfilesData] = useState<{
    publicProfile: TypeUserData | undefined;
    updatedProfile: TypeUserData | undefined | null;
  }>({ publicProfile: undefined, updatedProfile: undefined });

  const [profileSelectLang, setProfileSelectLang] = useState("en");

  const aboutMyselfRef = useRef<HTMLParagraphElement | null>(null);
  const aboutTeraphyRef = useRef<HTMLParagraphElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (aboutMyselfRef.current) {
      setTextHeight((prev) => ({
        ...prev,
        aboutMyself: aboutMyselfRef.current!.offsetHeight + 10,
      }));
    }

    if (aboutTeraphyRef.current) {
      setTextHeight((prev) => ({
        ...prev,
        aboutTeraphy: aboutTeraphyRef.current!.offsetHeight + 10,
      }));
    }
  }, [aboutMyselfRef.current, aboutTeraphyRef.current, selectedSpecialistData]);

  const getSpecialistsSessionsAmount = async (specialistId?: string) => {
    if (!token || !profileUserData) return;
    const response = await SpecialistApi.getSpecialistsSessionsAmount(
      profileUserData._id,
      specialistId
    );
    if (response.status && !specialistId) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
    if (response.status && specialistId) {
      setCurrentSpecialistTotalInfo((prev) => ({
        ...prev,
        sessionsTotal: response.amount,
      }));
    }
  };
  const getSpecialistsComments = async (specialistId?: string) => {
    if (!token || !profileUserData || !profileUserData._id || !specialistData)
      return;
    const commentsResponse = await SpecialistApi.getSpecialistsComments(
      profileUserData._id,
      100,
      1,
      specialistId
    );
    if (!specialistId && commentsResponse.status) {
      setTotalInfo((prev) => ({
        ...prev,
        reviewTotal: commentsResponse.totalAmount,
      }));
    }
    if (commentsResponse.status && specialistId)
      setCommentsData(commentsResponse.comments);
    setCurrentSpecialistTotalInfo((prev) => ({
      ...prev,
      reviewTotal: commentsResponse.totalAmount,
    }));
  };
  const getSpecialistSessionPackages = async () => {
    if (!token || !profileUserData || !specialistData) {
      return setActionLoaders((prev) => ({ ...prev, packages: false }));
    }
    setActionLoaders((prev) => ({ ...prev, packages: true }));
    const response = await SpecialistApi.getSpecialistSessionPackages(
      token,
      profileUserData._id,
      selectedSpecialistData?.specialistData._id
    );

    if (
      response.status &&
      response.packages &&
      response.overlapDays &&
      response.overlapDays[0]
    ) {
      const responseOverlaps = response.overlapDays[0].overlaps.map((item) => ({
        day: item.day,
        overlapTime: item.overlapTime,
      }));
      setPackagesData({
        packagesData: response.packages,
        overlaps: responseOverlaps,
      });
    }
    setActionLoaders((prev) => ({ ...prev, packages: false }));
    if (!response.status && response.message) {
      return Notify(response.message);
    }
  };
  useEffect(() => {
    if (!profileUserData) return;
    getSpecialistSessionPackages();
    getSpecialistsComments();
    getSpecialistsSessionsAmount();
    if (!selectedSpecialistData) return;
    getSpecialistsSessionsAmount(selectedSpecialistData.specialistData._id);
    getSpecialistsComments(selectedSpecialistData.specialistData._id);
    if (!selectedSpecialistData.specialistData.preferredLanguages) return;
    setProfileSelectLang(
      ISO639LangReversed[
        selectedSpecialistData.specialistData.preferredLanguages[0]
      ]
    );
  }, [selectedSpecialistData]);

  useEffect(() => {
    if (token) {
      const makeAsync = async () => {
        const userDataPublicResponse = await UsersApi.getUserPublicInfoById(
          userId,
          "en",
          token
        );

        const userDataResponse = await UsersApi.getUserProfileInfoById(
          token,
          userId
        );

        setProfilesData({
          publicProfile: userDataPublicResponse.user,
          updatedProfile: userDataResponse.user,
        });
        const availableUserData = !isPublicProfile
          ? userDataResponse.user
          : userDataPublicResponse.user;
        if (!userDataResponse.status || !userDataPublicResponse.status) {
          setIsOpen(false);
        }

        if (userDataResponse.status && availableUserData) {
          setProfileUserData(availableUserData);
          setSpecialistData(
            availableUserData.specialistIds &&
              availableUserData.specialistIds.map((item) => ({
                specializationValue: item.mainSpecializations[0].specialization,
                specializationLable: item.mainSpecializations[0].specialization,
                specialistData: item,
              }))
          );
        }
      };
      makeAsync();
    }
  }, [getPublicInfoString, userId]);
  useEffect(() => {
    if (!specialistData) return;
    setSelectedSpecialistData(specialistData[0]);
  }, [specialistData]);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (!video || !canvas) return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      setVideoPreviewImg((prev) => ({ ...prev, loader: true }));
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();

      setVideoPreviewImg((prev) => ({ ...prev, imgSrc: dataURL }));
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [profileUserData, specialistData, canvasRef.current, profileSelectLang]);

  const getProfileChangeDiff = async (specialistUserId: string) => {
    if (!token) return;
    const response = await SpecialistApi.getProfileChangeDiff(
      token,
      specialistUserId
    );

    if (response.status && response.changes) {
      if (response.changes.changedValues.specialistIds) {
        const specialistChanges =
          response.changes.changedValues.specialistIds.reduce(
            (acc: Record<string, string[]>, item) => {
              acc[item._id] = Object.keys(item);
              return acc;
            },
            {}
          );

        setProfileChanges((prev) => ({
          ...prev,
          specialistChanges: specialistChanges,
        }));
      }
      const userChanges = Object.keys(response.changes.changedValues).filter(
        (el) => el !== "specialistIds"
      );
      setProfileChanges((prev) => ({ ...prev, userChanges: userChanges }));
    }
  };
  useEffect(() => {
    if (!profileUserData) return;
    getProfileChangeDiff(profileUserData._id);
  }, [profileUserData]);

  const getVideoId = (
    videos: {
      video: VideoMetadata;
      language: string;
      processing?: boolean | undefined;
    }[],
    selectedLang: string
  ) => {
    const currentVideo =
      videos.find((el) => el.language === selectedLang) ||
      videos.find((el) => el.language === "en") ||
      videos[0];
    if (
      !currentVideo ||
      !currentVideo.video ||
      !currentVideo.video._id ||
      currentVideo.processing
    )
      return;
    return currentVideo.video._id;
  };

  if (loaders.mainLoader || !profileUserData) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className={s.container} id="profile-content">
      {showDiff ? (
        profilesData.publicProfile &&
        profilesData.updatedProfile &&
        profileChanges.specialistChanges &&
        initialSpecialization && (
          <ShowDiff
            newProfile={profilesData.updatedProfile}
            oldProfile={profilesData.publicProfile}
            newSpecialistData={
              profilesData.updatedProfile.specialistIds!.find(
                (el) => el._id === initialSpecialization
              )!
            }
            oldSpecialistData={
              profilesData.publicProfile.specialistIds!.find(
                (el) => el._id === initialSpecialization
              )!
            }
            specialistFieldsChanged={
              profileChanges.specialistChanges[initialSpecialization]
                ? profileChanges.specialistChanges[
                    initialSpecialization
                  ].filter((el) => el !== "_id")
                : []
            }
            userFieldsChanged={profileChanges.userChanges}
          />
        )
      ) : (
        <div className={s.profileInfoBlock}>
          <div className={s.headBlock}>
            <div className={s.headBlockMainContent}>
              <div className={s.headNameBlock}>
                <div className={s.mobileAvatarBlock}>
                  <CircleAvatar
                    userId={profileUserData?._id || ""}
                    name={getTranslation(profileUserData?.name) || ""}
                    surname={getTranslation(profileUserData?.surname) || ""}
                    height="auto"
                    width="100%"
                    fontSize="78px"
                    marginRight="0"
                    isModalAvatar
                    borderRadius="20px"
                    hideNoAvtaarBlock
                  />
                </div>
                <div className={s.nameBlock}>
                  <span className={s.nameTitle}>
                    {getTranslation(profileUserData?.name)}{" "}
                    {getTranslation(profileUserData?.surname)}
                    {selectedSpecialistData &&
                      selectedSpecialistData.specialistData.isVerified && (
                        <VerifiedIcon className={s.verifyIcon} />
                      )}
                  </span>
                  <span className={s.ageTitle}>
                    {calculateAge(new Date(profileUserData!.dateOfBirth!)) +
                      " " +
                      "y.o"}
                  </span>
                  <div className={s.rightMenuInfoBlock}>
                    <SpecializationIcon />
                    <span>
                      {selectedSpecialistData
                        ? selectedSpecialistData.specialistData
                            .mainSpecializations[0].specialization
                        : ""}{" "}
                      {selectedSpecialistData &&
                      selectedSpecialistData.specialistData.level
                        ? selectedSpecialistData.specialistData.level.title.find(
                            (el) => el.language === "en"
                          )?.text
                        : null}
                    </span>
                  </div>
                  <div className={s.rightMenuInfoBlock}>
                    <MessageIcon
                      className={s.infoIcon}
                      width={15}
                      height={15}
                    />
                    <span>
                      {specialistData &&
                        Array.from(
                          new Set(
                            specialistData.flatMap(
                              (spec) => spec.specialistData.preferredLanguages!
                            )
                          )
                        ).join(", ")}
                    </span>
                  </div>
                  <div className={s.rightMenuInfoBlock}>
                    <EearthIcon />
                    <span>{profileUserData && profileUserData.location}</span>
                  </div>
                </div>
              </div>

              {totalInfo.reviewTotal !== 0 && (
                <div className={s.totalInfoBlock}>
                  <span>Reviews</span>
                  <span>{totalInfo.reviewTotal}</span>
                </div>
              )}
              {totalInfo.sessionsTotal !== 0 && (
                <div className={s.totalInfoBlock}>
                  <span>Sessions on Talzi</span>
                  <span>{totalInfo.sessionsTotal}</span>
                </div>
              )}
              {profileUserData &&
              profileUserData.briefDescription &&
              profileUserData.briefDescription.length > 0 ? (
                <div className={s.briefDescription}>
                  {profileUserData.briefDescription.find(
                    (el) => el.language === profileSelectLang
                  )?.text ?? "-"}
                </div>
              ) : null}
            </div>
          </div>
          <div className={s.dynamiContentBlock}>
            <div className={s.dynamicHeadBlock}>
              <div className={s.tabsBlock}>
                {specialistData &&
                  selectedSpecialistData &&
                  specialistData.map((item, index) => (
                    <div
                      className={
                        selectedSpecialistData!.specialistData
                          .mainSpecializations[0].specialization ===
                        item.specializationValue
                          ? s.activeTab
                          : s.notActiveTab
                      }
                      onClick={() => {
                        setSelectedSpecialistData(
                          specialistData.find(
                            (el) =>
                              el.specializationValue ===
                              item.specializationValue
                          )
                        );
                      }}
                      key={index}
                    >
                      <span>{item.specializationLable}</span>
                      <span
                        className={
                          selectedSpecialistData!.specialistData
                            .mainSpecializations[0].specialization ===
                          item.specializationValue
                            ? s.tabActiveIndicator
                            : s.indicatorHidden
                        }
                      ></span>
                    </div>
                  ))}
              </div>
              <div className={s.profileSelectLangBlock}>
                {selectedSpecialistData &&
                  selectedSpecialistData.specialistData.preferredLanguages
                    ?.length && (
                    <MultipleSelect
                      data={selectedSpecialistData?.specialistData.preferredLanguages.map(
                        (lang) => ({
                          lable: lang,
                          value: ISO639LangReversed[lang],
                        })
                      )}
                      setValue={(value) =>
                        setProfileSelectLang(value as string)
                      }
                      multiplie={false}
                      value={profileSelectLang}
                    />
                  )}
              </div>
            </div>
            <div className={s.mainInfoBlock}>
              {selectedSpecialistData &&
                selectedSpecialistData.specialistData.price &&
                selectedSpecialistData.specialistData.price?.length > 1 && (
                  <SessionPrices
                    prices={selectedSpecialistData.specialistData.price}
                  />
                )}
              <div className={s.headRatingBlock}>
                {selectedSpecialistData &&
                  selectedSpecialistData.specialistData.price?.length === 1 && (
                    <div className={s.headRatingValueBlock}>
                      $
                      <div>
                        <span className={s.numberInfoValue}>
                          {
                            selectedSpecialistData.specialistData.price[0]
                              .amount
                          }
                        </span>
                        <span>Per session</span>
                      </div>
                    </div>
                  )}
                {commentsData.length &&
                selectedSpecialistData &&
                selectedSpecialistData?.specialistData.rating ? (
                  <div className={s.headRatingValueBlock}>
                    <GreenStar width={25} height={25} />
                    <div>
                      <span className={s.numberInfoValue}>
                        {selectedSpecialistData.specialistData.rating.toFixed(
                          1
                        )}
                      </span>
                      <span>Rating</span>
                    </div>
                  </div>
                ) : null}
                {currentSpecialistTotalInfo.sessionsTotal !== undefined && (
                  <div className={s.headRatingValueBlock}>
                    <SpecialitySessionIcon width={25} height={25} />
                    <div>
                      <span className={s.numberInfoValue}>
                        {currentSpecialistTotalInfo.sessionsTotal}
                      </span>
                      <span>Sessions on Talzi</span>
                    </div>
                  </div>
                )}
                {currentSpecialistTotalInfo.reviewTotal !== undefined && (
                  <div className={s.headRatingValueBlock}>
                    <SpecialistyReviewsIcon width={25} height={25} />
                    <div>
                      <span className={s.numberInfoValue}>
                        {currentSpecialistTotalInfo.reviewTotal}
                      </span>
                      <span>Reviews</span>
                    </div>
                  </div>
                )}
              </div>
              <div className={s.headRatingBlock}>
                {selectedSpecialistData?.specialistData.educationCertificates &&
                selectedSpecialistData?.specialistData.educationCertificates
                  .length ? (
                  <div className={s.totalCertificateBlock}>
                    <TotalEducationIcon width={25} height={25} />
                    <div>
                      <span className={s.totalCertificatesLable}>
                        {`Completed ${selectedSpecialistData?.specialistData.educationCertificates.length} the educational program`}
                      </span>
                    </div>
                  </div>
                ) : null}
                {selectedSpecialistData?.specialistData
                  .federationCertificates &&
                selectedSpecialistData?.specialistData.federationCertificates
                  .length ? (
                  <div className={s.totalCertificateBlock}>
                    <TotalFederationsIcon width={25} height={25} />
                    <div>
                      <span className={s.totalCertificatesLable}>
                        {`Member ${selectedSpecialistData?.specialistData.federationCertificates.length} of professional federation`}
                      </span>
                    </div>
                  </div>
                ) : null}
                {selectedSpecialistData?.specialistData
                  .qualificationCertificates &&
                selectedSpecialistData?.specialistData.qualificationCertificates
                  .length ? (
                  <div className={s.totalCertificateBlock}>
                    <TotalQualificationsIcon width={25} height={25} />
                    <div>
                      <span className={s.totalCertificatesLable}>
                        {`Qualification ${selectedSpecialistData?.specialistData.qualificationCertificates.length} obtained in federation`}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              {selectedSpecialistData &&
              selectedSpecialistData.specialistData.contributeToProfession &&
              selectedSpecialistData.specialistData.contributeToProfession
                .length ? (
                <div className={s.aboutTitleBlock}>
                  <h4 className={s.blockLable}>
                    Contribution to the profession
                  </h4>

                  <p className={s.aboutTitle} ref={aboutTeraphyRef}>
                    {selectedSpecialistData.specialistData.contributeToProfession.find(
                      (el) => el.language === profileSelectLang
                    )?.text ?? "-"}
                  </p>
                </div>
              ) : null}
              <div className={s.subSpecializationBlock}>
                <h4 className={s.blockLable}>Professional fields</h4>
                <div className={s.subSpecializationList}>
                  {selectedSpecialistData &&
                    selectedSpecialistData.specialistData.subSpecializations.map(
                      (item, index) => (
                        <div className={s.subSpecElement} key={index}>
                          <span className={s.subSpecIndicator}></span>
                          <span className={s.subSpecTitle}>
                            {
                              item.labels.find((el) => el.language === "en")
                                ?.text
                            }
                          </span>
                        </div>
                      )
                    )}
                </div>
              </div>
              {selectedSpecialistData &&
              selectedSpecialistData.specialistData.tags &&
              selectedSpecialistData.specialistData.tags.filter(
                (el) => el.level === 2
              ).length ? (
                <div className={s.workWithSection}>
                  <h4 className={s.blockLable}>I work with such requests</h4>
                  <div className={s.workWithList}>
                    {selectedSpecialistData.specialistData.tags
                      .filter((el) => el.level === 2)
                      .map((item, index) => (
                        <div className={s.workWithElement} key={index}>
                          {
                            item.labels.find((el) => el.language === "en")
                              ?.text!
                          }
                        </div>
                      ))}
                  </div>
                </div>
              ) : null}
              <div className={s.aboutVideoBlock}>
                {selectedSpecialistData &&
                selectedSpecialistData.specialistData.introductionVideo &&
                selectedSpecialistData.specialistData.introductionVideo &&
                selectedSpecialistData?.specialistData.introductionVideo.find(
                  (el) => el.language === profileSelectLang
                )?.video?._id.length ? (
                  selectedSpecialistData?.specialistData.introductionVideo.find(
                    (el) => el.language === profileSelectLang
                  )?.processing ? (
                    <div className={s.videoInProcessBlock}>
                      The server processes your video
                    </div>
                  ) : (
                    <div className={s.previewVideoBlock}>
                      <canvas ref={canvasRef} style={{ display: "none" }} />

                      <img
                        src={videoPreviewImg.imgSrc}
                        className={s.previewVideoImg}
                        style={{
                          display: videoPreviewImg.loader ? "none" : "block",
                        }}
                        alt=""
                        onLoad={() =>
                          setVideoPreviewImg((prev) => ({
                            ...prev,
                            loader: false,
                          }))
                        }
                      />
                      <div
                        className={s.videoPreviewSkeleton}
                        style={{
                          display: !videoPreviewImg.loader ? "none" : "block",
                        }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={168}
                          height={300}
                          className={s.videoSkeleton}
                        />
                      </div>

                      {videoPreviewImg && (
                        <PlayBtnIcon
                          className={s.playBtn}
                          onClick={() => setVideoModalOpen(true)}
                        />
                      )}
                    </div>
                  )
                ) : null}
                {(selectedSpecialistData?.specialistData.aboutMyself &&
                  selectedSpecialistData?.specialistData.aboutMyself.length) ||
                (selectedSpecialistData?.specialistData.aboutTherapy &&
                  selectedSpecialistData?.specialistData.aboutTherapy
                    .length) ? (
                  <div className={s.aboutTitleBlock}>
                    <h4 className={s.blockLable}>About me</h4>
                    <ReactSpoiler
                      collapsedSize={
                        textHeight.aboutMyself > 250
                          ? 250
                          : textHeight.aboutMyself
                      }
                      toggleContainerStyle={{ height: "fit-content" }}
                      showMoreComponent={
                        <div
                          className={s.showMoreBtn}
                          style={{
                            display:
                              textHeight.aboutMyself > 250 ? "flex" : "none",
                          }}
                        >
                          <p>Show more</p> <ChevronDown />
                        </div>
                      }
                      showLessComponent={
                        <div
                          className={s.showMoreBtn}
                          style={{
                            display:
                              textHeight.aboutMyself > 250 ? "flex" : "none",
                          }}
                        >
                          <p>Show less</p>
                          <ChevronDown className={s.chevronRotate} />
                        </div>
                      }
                    >
                      <div className={s.aboutTitle} ref={aboutMyselfRef}>
                        {selectedSpecialistData?.specialistData.aboutMyself &&
                        selectedSpecialistData?.specialistData.aboutMyself
                          .length > 0
                          ? selectedSpecialistData?.specialistData.aboutMyself.find(
                              (el) => el.language === profileSelectLang
                            )?.text ?? "-"
                          : selectedSpecialistData?.specialistData
                              .aboutTherapy &&
                            selectedSpecialistData?.specialistData.aboutTherapy
                              .length > 0 &&
                            (selectedSpecialistData?.specialistData.aboutTherapy.find(
                              (el) => el.language === profileSelectLang
                            )?.text ??
                              "-")}
                      </div>
                    </ReactSpoiler>
                  </div>
                ) : null}
              </div>
              {selectedSpecialistData?.specialistData.aboutTherapy &&
              selectedSpecialistData?.specialistData.aboutTherapy.length &&
              selectedSpecialistData?.specialistData.aboutMyself &&
              selectedSpecialistData?.specialistData.aboutMyself.length ? (
                <div className={s.aboutTitleBlock}>
                  <h4 className={s.blockLable}>How session goes with me</h4>
                  <ReactSpoiler
                    collapsedSize={
                      textHeight.aboutTeraphy > 290
                        ? 300
                        : textHeight.aboutTeraphy
                    }
                    showMoreComponent={
                      <div
                        className={s.showMoreBtn}
                        style={{
                          display:
                            textHeight.aboutTeraphy > 250 ? "flex" : "none",
                        }}
                      >
                        <p>Show more</p> <ChevronDown />
                      </div>
                    }
                    showLessComponent={
                      <div
                        className={s.showMoreBtn}
                        style={{
                          display:
                            textHeight.aboutTeraphy > 250 ? "flex" : "none",
                        }}
                      >
                        <p>Show less</p>
                        <ChevronDown className={s.chevronRotate} />
                      </div>
                    }
                  >
                    <p className={s.aboutTitle} ref={aboutTeraphyRef}>
                      {selectedSpecialistData?.specialistData.aboutTherapy.find(
                        (el) => el.language === profileSelectLang
                      )?.text ?? "-"}
                    </p>
                  </ReactSpoiler>
                </div>
              ) : null}
              {packagesData && packagesData.packagesData.length ? (
                <div className={s.packagesBlock}>
                  <h4 className={s.blockLable}>
                    {"Session packages" +
                      `( ${packagesData.packagesData.length})`}
                  </h4>
                  <div className={s.packagesListBlock}>
                    {packagesData.packagesData.map((item, index) => (
                      <PackageCard
                        profileUserData={profileUserData}
                        sessionPackage={item}
                        specialistData={selectedSpecialistData!.specialistData}
                        profileLang={profileSelectLang}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
              {selectedSpecialistData && (
                <div className={s.certificatesBlock}>
                  <EducationSection
                    specialistId={selectedSpecialistData.specialistData._id}
                    userId={profileUserData?._id!}
                    isPublicProfile={isPublicProfile}
                    specialistData={selectedSpecialistData.specialistData}
                  />
                </div>
              )}
              {commentsData && commentsData.length ? (
                <ReviewsSection commentsData={commentsData} />
              ) : null}
            </div>
          </div>
        </div>
      )}

      {selectedSpecialistData &&
        selectedSpecialistData?.specialistData.introductionVideo &&
        selectedSpecialistData?.specialistData.introductionVideo.length > 0 &&
        getVideoId(
          selectedSpecialistData?.specialistData.introductionVideo,
          profileSelectLang
        ) && (
          <video
            ref={videoRef}
            src={url(
              `${connectionString}specialists/`,
              specialists.getSpecialistIntroVideo,
              {
                id: profileUserData?._id,
                videoId: getVideoId(
                  selectedSpecialistData?.specialistData.introductionVideo,
                  profileSelectLang
                ),
              }
            )}
            style={{ display: "none" }}
            crossOrigin="anonymous"
          />
        )}

      {selectedSpecialistData &&
        selectedSpecialistData?.specialistData.introductionVideo &&
        selectedSpecialistData?.specialistData.introductionVideo.length > 0 &&
        getVideoId(
          selectedSpecialistData?.specialistData.introductionVideo,
          profileSelectLang
        ) && (
          <ModalWindow
            isOpen={videoModalOpen}
            setIsOpen={setVideoModalOpen}
            bgColor="transparent"
            width="fit-content"
          >
            <video
              src={url(
                `${connectionString}specialists/`,
                specialists.getSpecialistIntroVideo,
                {
                  id: profileUserData?._id,
                  videoId: getVideoId(
                    selectedSpecialistData?.specialistData.introductionVideo,
                    profileSelectLang
                  ),
                }
              )}
              crossOrigin="anonymous"
              controls
              autoPlay
              className={s.video}
            />
          </ModalWindow>
        )}
    </div>
  );
};

export default Profile;
