import countries from "i18n-iso-countries";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UsersApi from "../../api/usersApi";
import { ReactComponent as ChevronLeftIcon } from "../../assets/Specialists/chevronLeft.svg";
import MagnifierIcon from "../../assets/Specialists/magnifier.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import { TypeUserData } from "../../types/TypeUsers";
import { getTranslation } from "../../utils/getTranslation";
import { formatDate } from "../../utils/setupProfileInfo";
import Profile from "../Profile/Profile";
import s from "./Users.module.css";
import UsersTable from "./UsersTable/UsersTable";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Users = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const filterRef = useRef<any>(null);
  const [search, setSearch] = useState("");
  const [searchByEmail, setSearchByEmail] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [usersData, setUsersData] = useState<TypeUserData[] | undefined>();
  const [page, setPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUser, setSelectedUser] = useState<TypeUserData | undefined>();
  const [avatars, setAvatars] = useState<{ [userId: string]: string }>({});
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isUpdatedProfileModalOpen, setIsUpdatedProfileModalOpen] =
    useState(false);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && usersData && usersData.length > 0) {
        /* socket.off(chat.newDialogMessage, handleMessage);
        socket.on(chat.newDialogMessage, handleMessage); */

        const promises = usersData.map((spec) =>
          UsersApi.getUserPublicAvatarAndName(spec._id)
        );
        const responses = await Promise.all(promises);
        const avatarsData = responses.reduce((acc, response) => {
          if (response.avatar) {
            acc[response._id] = response.avatar;
          }
          return acc;
        }, {} as { [userId: string]: string });

        setAvatars(avatarsData);
      }
    };
    makeAsync();
  }, [usersData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData) {
        const payload = {
          userId: userData._id,
          filters: {
            name: search.length > 0 ? search : "",
            email: searchByEmail.length > 0 ? searchByEmail : "",
            page: page + 1,
            limit: rowsPerPage,
            sort: {
              field: "_id",
              type: -1,
            },
          },
        };
        setIsLoader(true);
        const response = await UsersApi.getFilteredClients(token, payload);
        if (response.paramsAvailable && response.paramsAvailable.totalAmount) {
          setTotalAmount(response.paramsAvailable.totalAmount);
        }
        if (response.users && !response.users.length) {
          setUsersData(undefined);
        }
        if (response.status && response.paramsAvailable) {
          setUsersData(response.users);
        }
        setIsLoader(false);
        if (response.users && !response.users.length) return;
      }
    };
    makeAsync();
  }, [page, rowsPerPage, search, searchByEmail]);

  const selectUserHandler = (specialistId: string) => {
    if (!usersData) return;
    const selectedUser = usersData.filter(
      (item) => item._id === specialistId
    )[0];
    setSelectedUser(selectedUser);
  };

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <div className={s.pageInfoTitleBlock}>
          <h1>Clients</h1>
          {/*       <span>84% verified user profiles</span> */}
        </div>
      </div>
      <div className={s.filterBlock}>
        <div className={s.searchInputBlock}>
          <input
            type="text"
            className={s.searchInput}
            placeholder="Search by name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={MagnifierIcon} alt="" className={s.magnifierIcon} />
        </div>
        <div className={s.searchInputBlock}>
          <input
            type="text"
            className={s.searchInput}
            placeholder="Search by email..."
            value={searchByEmail}
            onChange={(e) => setSearchByEmail(e.target.value)}
          />
          <img src={MagnifierIcon} alt="" className={s.magnifierIcon} />
        </div>
      </div>
      <div className={s.contentBlock}>
        <div className={s.tableBlock}>
          {usersData && usersData.length ? (
            <UsersTable
              rows={usersData.map((item, index) => ({
                id: item._id,
                name:
                  getTranslation(item.name) +
                  " " +
                  getTranslation(item.surname),
                email: item.email,
              }))}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              selectSpecialistHandler={selectUserHandler}
              count={totalAmount}
            />
          ) : isLoader ? (
            <div className={s.noSpecialistBlock}>
              <Loader size={80} />{" "}
            </div>
          ) : (
            <div className={s.noSpecialistBlock}>
              <h1>No users found</h1>
            </div>
          )}
        </div>
        {usersData && usersData.length ? (
          <div
            className={`${s.specialistInfoBlock} ${
              selectedUser && s.specialistInfoBlockActive
            }`}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={s.backBlock}
                role="button"
                onClick={() => setSelectedUser(undefined)}
              >
                <ChevronLeftIcon className={s.chevronBackIcon} />
                Back
              </div>
              {selectedUser && (
                <StandartButton
                  buttonTitle="Send message"
                  action={() => {
                    navigate("/chat", {
                      state: {
                        userIdForCreate: selectedUser._id,
                      },
                    });
                  }}
                />
              )}
            </div>

            <div className={s.headNameBlock}>
              {selectedUser && (
                <CircleAvatar
                  /*  userId={selectedChat.members._id} */
                  name={getTranslation(selectedUser?.name)}
                  surname={getTranslation(selectedUser?.surname)}
                  avatarProps={avatars[selectedUser._id]}
                />
              )}
              <div className={s.nameEmailBlock}>
                <span>
                  {getTranslation(selectedUser?.name) +
                    " " +
                    getTranslation(selectedUser?.surname)}
                </span>
                <span>{selectedUser?.email}</span>
              </div>
            </div>
            {selectedUser && (
              <div className={s.mainInfoBlock}>
                <div className={s.infoTitleBlock}>
                  <span>Personal info</span>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>Name</span>

                      <span className={s.infoValue}>
                        {getTranslation(selectedUser.name)}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>Surname</span>

                      <span className={s.infoValue}>
                        {getTranslation(selectedUser.surname)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>Date of birth</span>

                      <span className={s.infoValue}>
                        {formatDate(
                          selectedUser.dateOfBirth
                            ? new Date(selectedUser.dateOfBirth)
                            : new Date()
                        )}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>Time zone</span>

                      <span className={s.infoValue}>
                        {selectedUser.timezone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>Place of residence</span>

                      <span className={s.infoValue}>
                        {selectedUser.location}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <ModalWindow
        isOpen={isProfileModalOpen}
        setIsOpen={setIsProfileModalOpen}
        height="90vh"
        width="90vw"
        padding="0 20px"
        /*  bgColor="rgb(46,46,46)" */
      >
        <div className={s.profileContainer}>
          {selectedUser && (
            <Profile
              userId={selectedUser._id}
              isOpen={isProfileModalOpen}
              setIsOpen={setIsProfileModalOpen}
              getPublicInfoString={`${selectedUser.latinName}-${
                selectedUser.latinSurname
              }-${selectedUser._id.slice(-7)}`}
              isPublicProfile
            />
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={isUpdatedProfileModalOpen}
        setIsOpen={setIsUpdatedProfileModalOpen}
        height="90vh"
        width="90vw"
        padding="0 20px"
        /*  bgColor="rgb(46,46,46)" */
      >
        <div className={s.profileContainer}>
          {selectedUser && (
            <Profile
              userId={selectedUser._id}
              isOpen={isUpdatedProfileModalOpen}
              setIsOpen={setIsUpdatedProfileModalOpen}
              getPublicInfoString={`${selectedUser.latinName}-${
                selectedUser.latinSurname
              }-${selectedUser._id.slice(-7)}`}
              isPublicProfile={false}
              initialSpecialization={selectedUser._id}
            />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default Users;
