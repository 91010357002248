const url = (
  baseUrl: string,
  method: string,
  params?: Record<
    string,
    string | string[] | number | boolean | Date | undefined
  >,
  queryParams?: URLSearchParams
) => {
  let url = baseUrl + method;

  if (haveParams(url) && (!params || !Object.keys(params).length)) {
    console.error("Incorrect url function use detected!");
  }

  if (params && Object.keys(params).length)
    Object.keys(params).forEach((key) => {
      if (url.includes(`:${key}`))
        url = url.replace(
          `:${key}`,
          params[key] !== undefined ? params[key]!.toString() : ""
        );
    });
  if (queryParams) url = url + "?" + queryParams;
  return url;
};

function haveParams(url: string) {
  const regex = /: [A-Za-z]+ /;
  return regex.test(url);
}

export default url;
