import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as ChevronDownIcon } from "../../../assets/General/chevronDown.svg";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import { ContextProvider } from "../../../contextProvider";
import {
  SpecialistData,
  SpecialistLevel,
  TaxCategory,
} from "../../../types/TypeSpecialist";
import { TypeUserData } from "../../../types/TypeUsers";
import { getTranslation } from "../../../utils/getTranslation";
import Notify from "../../../utils/toaster";
import Profile from "../../Profile/Profile";
import s from "./SpecialistSettings.module.css";

type SpecialistSettingsProps = {
  selectedSpecialist: SpecialistData | undefined;
  setSeletedSpecialityInfo: Dispatch<
    SetStateAction<SpecialistData | undefined>
  >;
  setSelectedSpecialist: Dispatch<SetStateAction<TypeUserData | undefined>>;
  setSpecialistData: Dispatch<SetStateAction<TypeUserData[] | undefined>>;
  setIsUpdatedProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPublicProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  getPublicInfoString: string;
  userId: string;
};

const SpecialistSettings = ({
  selectedSpecialist,
  setSelectedSpecialist,
  setSpecialistData,
  setIsUpdatedProfileModalOpen,
  setIsPublicProfileModalOpen,
  setSeletedSpecialityInfo,
  getPublicInfoString,
  userId,
}: SpecialistSettingsProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [selectStatusCollapsed, setSelectStatusCollapsed] = useState({
    verification: true,
    subscriptions: true,
    level: true,
    changes: true,
    categories: true,
  });
  const [diffModal, setDiffModal] = useState(false);
  const [levelsData, setLevelsData] = useState<SpecialistLevel[] | undefined>();
  const [categories, setCategories] = useState<TaxCategory[]>([]);

  const changeVerifuStatusHandler = async (status: boolean) => {
    if (!token || !userData || !selectedSpecialist) return;
    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, isVerified: status },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, verification: true }));
      setSeletedSpecialityInfo((prev) => ({ ...prev!, isVerified: status }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, isVerified: status };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, isVerified: status };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
    if (!response.status && response.message) {
      setSelectStatusCollapsed((prev) => ({ ...prev, verification: true }));
      Notify(response.message);
    }
  };

  const changeVerifyProfileStatus = async (status: boolean) => {
    if (!token || !userData || !selectedSpecialist) return;

    const response = await SpecialistApi.update(
      {
        _id: selectedSpecialist._id,
        isVerifiedChanges: status,
        reviewRequested: false,
      },
      token
    );

    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, changes: true }));
      setSeletedSpecialityInfo((prev) => ({
        ...prev!,
        isVerifiedChanges: status,
        reviewRequested: false,
      }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return {
                ...item,
                isVerifiedChanges: status,
                reviewRequested: false,
              };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return {
                    ...item,
                    isVerifiedChanges: status,
                    reviewRequested: false,
                  };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
    if (!response.status && response.message) {
      setSelectStatusCollapsed((prev) => ({ ...prev, changes: true }));
      Notify(response.message);
    }
  };
  /*   const changeShowInSubscriptionStatusHandler = async (status: boolean) => {
    if (!token || !userData || !selectedSpecialist) return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, showInSubscriptions: status },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, subscriptions: true }));
      setSeletedSpecialityInfo((prev) => ({
        ...prev!,
        showInSubscriptions: status,
      }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, showInSubscriptions: status };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, showInSubscriptions: status };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  }; */
  const changeLevelHandler = async (level: SpecialistLevel) => {
    if (!token || !userData || !selectedSpecialist) return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, level: level._id },
      token
    );

    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, level: true }));
      //@ts-expect-error
      setSeletedSpecialityInfo((prev) => ({ ...prev!, level: level }));
      //@ts-expect-error
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, level: level };
            else return item;
          }),
        };
      });
      //@ts-expect-error
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, level: level };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };

  const setSpecialistTaxCategory = async (categoryId: string) => {
    if (!selectedSpecialist || !token) return;
    const payload = {
      specialistId: selectedSpecialist._id,
      categoryId: categoryId,
    };
    const response = await SpecialistApi.setSpecialistTaxCategory(
      token,
      payload
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, categories: true }));
      setSeletedSpecialityInfo((prev) => ({
        ...prev!,
        taxCategory: categoryId,
      }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, taxCategory: categoryId };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;
        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, taxCategory: categoryId };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!selectedSpecialist) return;
      const payload = {
        mainSpecId: selectedSpecialist.mainSpecializations[0]._id,
      };
      const response = await SpecialistApi.getAllSpecialistLevels(payload);

      if (response.status && response.levels) {
        setLevelsData(response.levels);
      }
    })();
  }, [selectedSpecialist]);

  useEffect(() => {
    (async () => {
      if (!selectedSpecialist || !token) return;
      const response = await SpecialistApi.getTaxCategories(
        token,
        selectedSpecialist.mainSpecializations[0]._id
      );
      if (response.status && response.categories) {
        setCategories(response.categories);
      }
    })();
  }, [selectedSpecialist]);

  return (
    <div className={s.settingsBlock}>
      <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Verification status</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.verification
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                verification: !prev.verification,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.isVerified
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.isVerified ? "Verified" : "Not verified"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.verification
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  verification: !prev.verification,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.verification ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifuStatusHandler(true)}
            >
              <span className={s.verifiedColumn}>Verified</span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifuStatusHandler(false)}
            >
              <span className={s.unverifiedColumn}>Not verified</span>
            </div>
          </AnimateHeight>
        </div>
      </div>
      {/* <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Participation in subscriptions</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.subscriptions
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                subscriptions: !prev.subscriptions,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.showInSubscriptions
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.showInSubscriptions
                ? "Participates"
                : "Does not participate"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.verification
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  subscriptions: !prev.subscriptions,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.subscriptions ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeShowInSubscriptionStatusHandler(true)}
            >
              <span className={s.verifiedColumn}>Participates</span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeShowInSubscriptionStatusHandler(false)}
            >
              <span className={s.unverifiedColumn}>Does not participate</span>
            </div>
          </AnimateHeight>
        </div>
      </div> */}
      <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Level</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.level
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                level: !prev.level,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.level
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.level
                ? selectedSpecialist?.level.level.toLowerCase()
                : "No level"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.level
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  level: !prev.level,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.level ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            {levelsData &&
              levelsData.map((item, index) => (
                <div
                  className={s.selectStatusElement}
                  role="button"
                  onClick={() => changeLevelHandler(item)}
                  key={index}
                >
                  <span className={s.verifiedColumn}>
                    {item.level.toLowerCase()}
                  </span>
                </div>
              ))}
          </AnimateHeight>
        </div>
      </div>
      <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Tax category</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.categories
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                categories: !prev.categories,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.taxCategory
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.taxCategory
                ? getTranslation(
                    categories.find(
                      (el) => el._id === selectedSpecialist?.taxCategory
                    )?.title
                  )
                : "No category"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.categories
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  categories: !prev.categories,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.categories ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            {categories &&
              categories.map((item, index) => (
                <div
                  className={s.selectStatusElement}
                  role="button"
                  onClick={() => setSpecialistTaxCategory(item._id)}
                  key={index}
                >
                  <span className={s.verifiedColumn}>
                    {getTranslation(item.title, "en")}
                  </span>
                </div>
              ))}
          </AnimateHeight>
        </div>
      </div>
      <div className={s.profileChangeStatusBlock}>
        <div className={s.profileVerifyStatusTitleBlock}>
          <div className={s.statusBlockTitle}>
            <span></span>
            <span>Profile changes status</span>
          </div>
          <div className={s.openProfileBtnsBlock}>
            <span style={{ color: "white" }}>View</span>
            <div
              className={s.viewUpdatedProfileBtn}
              role="button"
              onClick={() => setIsPublicProfileModalOpen(true)}
            >
              current
            </div>
            <div
              className={s.viewUpdatedProfileBtn}
              role="button"
              onClick={() => setIsUpdatedProfileModalOpen(true)}
            >
              updated
            </div>
            <div
              className={s.viewUpdatedProfileBtn}
              role="button"
              onClick={() => setDiffModal(true)}
            >
              difference
            </div>
            <span style={{ color: "white" }}>profile</span>
          </div>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.changes
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                changes: !prev.changes,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.isVerifiedChanges
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.isVerifiedChanges
                ? "Verified"
                : "Not verified"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.changes
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  changes: !prev.changes,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.changes ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifyProfileStatus(true)}
            >
              <span className={s.verifiedColumn}>Verified</span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifyProfileStatus(false)}
            >
              <span className={s.unverifiedColumn}>Not verified</span>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <ModalWindow
        isOpen={diffModal}
        setIsOpen={setDiffModal}
        height="90vh"
        width="90vw"
        padding="0"
      >
        <div className={s.profileContainer}>
          {selectedSpecialist && (
            <Profile
              userId={userId}
              isOpen={diffModal}
              setIsOpen={setDiffModal}
              getPublicInfoString={getPublicInfoString}
              isPublicProfile={true}
              initialSpecialization={selectedSpecialist!._id}
              showDiff
            />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default SpecialistSettings;
