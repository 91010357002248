import { CircularProgress, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import StatisticApi from "../../api/statisticApi";
import { theme } from "../../utils/theme";
import s from "./Dashboard.module.css";
import StatisticsCard from "./StatisticsCard/StatisticsCard";

type StatisticData = {
  generalStats: { title: String; value: number; unit?: string }[] | undefined;
  profilesStats: { title: String; value: number; unit?: string }[] | undefined;
};

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const [statisticData, setStatisticData] = useState<StatisticData>();
  const [loader, setLoader] = useState({ mainLoader: false });

  useEffect(() => {
    if (!token) return;
    const makeAsync = async () => {
      setLoader((prev) => ({ ...prev, mainLoader: true }));
      const response = await StatisticApi.getAdminDashboardStats(token);
      setLoader((prev) => ({ ...prev, mainLoader: false }));
      if (response.status && response.statistics) {
        const generalStats = [
          {
            title: "Clients Amount",
            value: response.statistics.defaultUsersAmount,
          },
          {
            title: "Specialists Amount",
            value: response.statistics.specialistUsersAmount,
          },
          {
            title: "Sessions Amount",
            value: response.statistics.sessionsAmount,
          },
          {
            title: "Completed Sessions Amount",
            value: response.statistics.completedSessionsAmount,
          },
          {
            title: "Sessions Average Duration",
            value: response.statistics.sessionsAverageDuration,
            unit: "(min)",
          },
        ];
        const profilesStats = [
          {
            title: "Total Specialists`s Profiles",
            value: response.statistics.specialistProfilesAmount,
          },
          {
            title: "Ready to Review",
            value: response.statistics.profilesReadyToReview,
          },
          {
            title: "Active Profiles",
            value: response.statistics.activeProfiles,
          },
        ];
        setStatisticData({
          generalStats: generalStats,
          profilesStats: profilesStats,
        });
      }
    };
    makeAsync();
  }, [token]);
  if (loader.mainLoader)
    return (
      <div className={s.mainLoaderContainer}>
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );

  return (
    <div className={s.container}>
      <div>
        <h2 className={s.statsLabel}>General statistics</h2>
        {statisticData && statisticData.generalStats && (
          <div className={s.statsListBlock}>
            {statisticData.generalStats.map((item, index) => (
              <div className={s.statisticCardBlock} key={index}>
                <StatisticsCard
                  title={item.title as string}
                  value={Math.round(item.value)}
                  unit={item.unit}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <h2 className={s.statsLabel}>Profiles statistics</h2>
        {statisticData && statisticData.profilesStats && (
          <div className={s.statsListBlock}>
            {statisticData.profilesStats.map((item, index) => (
              <div className={s.statisticCardBlock} key={index}>
                <StatisticsCard
                  title={item.title as string}
                  value={Math.round(item.value)}
                  unit={item.unit}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
