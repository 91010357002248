import { network } from "../config";
import { DialogChat } from "../types/DialogChat";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  ParamsAvailable,
  SpecialistData,
  TypeSpecialistFilter,
} from "../types/TypeSpecialist";
import { GetUserAvatar, TypeUserData } from "../types/TypeUsers";
import url from "../utils/formApiUrl";
import customKy from "./kyExtend";
const { users } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "users/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class UsersApi {
  static async getUserSelectedFields(
    token: string,
    userId: string,
    userFields: string,
    specFields: string
  ): Promise<TypeDefaultResponse & { userData?: Partial<TypeUserData> }> {
    return await customKy
      .get(
        url(baseURL, users.getUserSelectedFields, {
          id: userId,
          userFields,
          specFields,
        }),
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getUserProfileInfoById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistId?: SpecialistData }) | null;
    }
  > {
    return await customKy
      .get(url(baseURL, users.getUserProfileInfoById, { id }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserByToken(token: string): Promise<TypeUserData | null> {
    return await customKy
      .get(url(baseURL, users.getUserByToken, { token }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async auth(
    phoneNumberOrEmail: string,
    password: string,
    selectedLanguage: string,
    rememberMe?: boolean
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
      refreshToken?: string;
    }
  > {
    return await customKy
      .post(url(baseURL, users.auth), {
        json: {
          phoneNumberOrEmail: phoneNumberOrEmail,
          password: password,
          selectedLanguage: selectedLanguage,
          rememberMe: rememberMe,
        },
      })
      .then((res) => res.json());
  }

  static async getUserAvatarAndName(
    token: string,
    id: string
  ): Promise<GetUserAvatar> {
    return await customKy
      .get(url(baseURL, users.getUserAvatarAndName, { id }), {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserPublicAvatarAndName(id: string): Promise<GetUserAvatar> {
    return await customKy
      .get(url(baseURL, users.getUserPublicAvatarAndName, { id }), {})
      .then((res) => res.json());
  }

  static async getUserPublicInfoById(
    id: string,
    language: string,
    token?: string
  ): Promise<
    TypeDefaultResponse & {
      user?: TypeUserData & { specialistIds?: SpecialistData[] };
    }
  > {
    return await customKy
      .get(url(baseURL, users.getUserPublicInfoById, { id, language }), {
        headers: token ? getAuthHeader(token) : undefined,
      })
      .then((res) => res.json());
  }

  static async refreshAccessToken(payload: { refreshToken: string }): Promise<
    TypeDefaultResponse & {
      token?: string;
    }
  > {
    return await customKy
      .patch(url(baseURL, users.refreshAccessToken), {
        json: payload,
      })
      .then((res) => res.json());
  }

  static async logout(payload: {
    userId: string;
  }): Promise<TypeDefaultResponse> {
    return await customKy
      .post(url(baseURL, users.logout), {
        json: payload,
      })
      .then((res) => res.json());
  }

  static async findUserBySearchString(
    token: string,
    searchString: string,
    searchForSpecialistOnly?: "true" | "false"
  ): Promise<TypeDefaultResponse & { users?: TypeUserData[] }> {
    return await customKy
      .get(
        url(baseURL, users.findUserBySearchString, {
          string: searchString,
          specialistOnly: searchForSpecialistOnly,
        }),
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getFilteredClients(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
      alreadyFetched?: string[];
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
    }
  > {
    return await customKy
      .post(url(baseURL, users.getFilteredClients), {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUsersDialogChats(
    token: string,
    userId: string,
    limit: number,
    page: number,
    name?: string
  ): Promise<
    TypeDefaultResponse & {
      chats: DialogChat[];
    }
  > {
    const params = new URLSearchParams();
    if (name) {
      params.append("name", name);
    }
    return await customKy
      .get(
        url(
          baseURL,
          users.getUsersDialogChats,
          { id: userId, page, limit },
          params
        ),
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
}
