import { Skeleton, Tooltip } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as EditIcon } from "../../../../assets/Certificates/edit.svg";
import { ReactComponent as ViewFileIcon } from "../../../../assets/Profile/specialist/view-file.svg";
import BlurImageEditor from "../../../../components/BlurImageEditor/BlurImageEditor";
import BlurPdfEditor from "../../../../components/BlurPdfEditor/BlurPdfEditor";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../../contextProvider";
import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
  SpecialistData,
} from "../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../utils/dateActions";
import { getPdfThumbnail } from "../../../../utils/filesAction";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import UpdateCertificatesModal from "../AddCertificatesModal/UpdateCertificatesModal";
import s from "../EducationSection.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type EducationCardProps = {
  federationData: FederationsCertificatesResponse;
  previewFileHandler: (link: string, type: string) => void;
  specialistId: string;
  specialistData: SpecialistData;
  setCertificatesData: Dispatch<SetStateAction<CertificatesData>>;
};

const FederationCard = ({
  federationData,
  previewFileHandler,
  specialistId,
  specialistData,
  setCertificatesData,
}: EducationCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [certificateThumbnail, setCertificateThumbnail] = useState<
    string | undefined
  >();
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [imageEditorModal, setImageEditorModal] = useState(false);
  const [certificateLink, setCertificateLink] = useState<string | undefined>(
    federationData.link
  );
  const [educationModal, setEducationModal] = useState(false);

  useEffect(() => {
    setCertificateLink(federationData.link);
  }, [federationData.link]);

  useEffect(() => {
    if (!certificateLink) return;
    const loadThumbnail = async () => {
      if (federationData.type === "pdf") {
        setThumbnailLoader(true);
        const thumbnail = await getPdfThumbnail(certificateLink);
        setCertificateThumbnail(thumbnail);
        setThumbnailLoader(false);
      } else {
        setCertificateThumbnail(certificateLink);
      }
    };

    loadThumbnail();
  }, [certificateLink]);

  const updateCertificateFile = async (newFile: File) => {
    if (!token) return;
    const payload = {
      file: newFile,
      specialistId: specialistId,
      updateData: { _id: federationData._id },
    };
    const response = await SpecialistApi.updateFederationCertificate(
      payload,
      token
    );
    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.certificate) {
      setImageEditorModal(false);
      setCertificateLink(response.certificate.link);
      SuccesNotify("Certificate has been updated");
    }
  };

  return (
    <div className={s.certificateElement}>
      {certificateThumbnail && !thumbnailLoader ? (
        <Tooltip arrow title={"Show certificate"}>
          <div
            onClick={() =>
              previewFileHandler(federationData.link, federationData.type)
            }
            className={s.showCertificateBtn}
          >
            <img
              src={certificateThumbnail}
              alt="PDF Thumbnail"
              className={s.thumbnailCertificate}
            />
          </div>
        </Tooltip>
      ) : (
        <Skeleton variant="rounded" width={"100%"} height={200} />
      )}
      <div className={s.mainInfoCertificateBlock}>
        <div className={s.headCertificateBlock}>
          <span>
            {federationData.federation.text.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text ?? federationData.federation.text[0].text}
          </span>
        </div>
        <div className={s.bottomCertificateBlock}>
          <span>
            {formatEducationPeriod(
              new Date(federationData.membershipStart),
              new Date(federationData.membershipEnd),
              userData?.selectedLanguage!
            )}
          </span>
          <Tooltip arrow title={"Show federation profile"}>
            <a
              href={federationData.federationProfileUrl}
              className={s.showProfileBtn}
              target="_blank"
            >
              <ViewFileIcon />
            </a>
          </Tooltip>
        </div>
        <div className={s.actionBlock}>
          {federationData.link ? (
            <StandartButton
              action={() => setImageEditorModal(true)}
              buttonTitle="Blur surname"
              width="calc(100% - 62px)"
            />
          ) : (
            <div></div>
          )}
          <div
            role="button"
            className={s.editBtn}
            onClick={() => setEducationModal(true)}
          >
            <EditIcon />
          </div>
        </div>
      </div>
      {federationData.link && (
        <ModalWindow
          isOpen={imageEditorModal}
          setIsOpen={setImageEditorModal}
          width="fit-content"
        >
          {federationData.type !== "pdf" ? (
            <BlurImageEditor
              imageUrl={federationData.link}
              saveImage={updateCertificateFile}
            />
          ) : (
            <BlurPdfEditor
              pdfUrl={federationData.link}
              savePdf={updateCertificateFile}
            />
          )}
        </ModalWindow>
      )}
      <UpdateCertificatesModal
        certificateType="federation"
        modalOpen={educationModal}
        setModalOpen={setEducationModal}
        specialistId={specialistData?._id!}
        setNewCertificate={setCertificatesData}
        mainSpec={specialistData?.mainSpecializations[0]._id!}
        federationToUpdate={federationData}
      />
    </div>
  );
};
export default FederationCard;
