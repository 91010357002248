import { Tooltip } from "@mui/material";
import countries from "i18n-iso-countries";
import { useContext, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import ReactFlagsSelect from "react-flags-select";
import { Link, useNavigate } from "react-router-dom";
import SpecialistApi from "../../api/specialistsApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as AllertCircle } from "../../assets/General/alert-circle.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/General/chevronDown.svg";
import { ReactComponent as CloseIcon } from "../../assets/General/close.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/Specialists/chevronLeft.svg";
import { ReactComponent as FilterIcon } from "../../assets/Specialists/filter-icon.svg";
import MagnifierIcon from "../../assets/Specialists/magnifier.svg";
import { ReactComponent as VerifyIcon } from "../../assets/Specialists/verify.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import SpecialistTable from "../../components/SpecialistTable/SpecialistTable";
import { ContextProvider } from "../../contextProvider";
import { SessionComment } from "../../types/TypeSession";
import { SpecialistData } from "../../types/TypeSpecialist";
import { TypeUserData } from "../../types/TypeUsers";
import { calculateAge } from "../../utils/general";
import getAvailableParams from "../../utils/getAvailableParams";
import { getTranslation } from "../../utils/getTranslation";
import {
  getExtraElements,
  removeFilterElements,
} from "../../utils/specialistFilter";
import Profile from "../Profile/Profile";
import CommentCard from "./CommentCard/CommentCard";
import ServicesConfigs from "./ServicesConfigs/ServicesConfigs";
import s from "./Specialists.module.css";
import SpecialistSettings from "./SpecialistSettings/SpecialistSettings";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const genderSelectData = [
  {
    value: "Male",
    lable: "Male",
  },
  {
    value: "Female",
    lable: "Female",
  },
  {
    value: "Non-binary",
    lable: "Non-binary",
  },
];
const statusSelectData = [
  {
    value: undefined,
    lable: "All",
  },
  {
    value: true,
    lable: "Verified",
  },
  {
    value: false,
    lable: "Unverified",
  },
];
const statusRequestSelectedData = [
  {
    value: undefined,
    lable: "All",
  },
  {
    value: {
      verifyProfileRequest: undefined,
      servicesRequest: true,
      reviewRequested: undefined,
    },
    lable: "Services requests",
  },
  {
    value: {
      verifyProfileRequest: true,
      servicesRequest: undefined,
      reviewRequested: undefined,
    },
    lable: "Unverified changes",
  },
  {
    value: {
      verifyProfileRequest: undefined,
      servicesRequest: undefined,
      reviewRequested: true,
    },
    lable: "Ready for review",
  },
];
type SelectData = {
  value: string;
  lable: string;
};
type SpecializationsState = {
  mainSpecData: SelectData[];
  subSpecData?: SelectData[];
  selectedMainSpec?: string | string[];
  selectedSubSpec?: string | string[];
};

const Specialists = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const filterRef = useRef<any>(null);
  const [search, setSearch] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [availableCountry, setAvailableCountry] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });
  const [availableCity, setAvailableCity] = useState<
    { id: number; lable: string }[]
  >([]);
  const [selectedCity, setSelectedCity] = useState<string | string[]>([]);
  const [languagesList, setLanguagesList] = useState<
    { value: string; lable: string }[]
  >([]);
  const [selectedLangs, setSelectedLangs] = useState<string[]>([]);

  const [filterCounter, setFilterCounter] = useState(0);
  const [filterRanges, setFilterRanges] = useState({
    age: { min: 0, max: 0 },
    price: { min: 0, max: 0 },
  });
  const [specializations, setSpecializations] = useState<SpecializationsState>({
    mainSpecData: [],
  });
  const [availableGenders, setAvailableGenders] = useState<
    | {
        value: string;
        lable: string;
      }[]
    | null
  >(null);
  const [genderFilterValue, setGenderFilterValue] = useState<string | string[]>(
    []
  );
  /*   const [pagginatuions, setPagginations] = useState({
    total: 0,
    currentPage: 1,
  }); */
  const [isLoader, setIsLoader] = useState(false);
  const [specialistData, setSpecialistData] = useState<
    TypeUserData[] | undefined
  >();
  const [page, setPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [verifiedStatus, setVerifiedStatus] = useState<boolean | undefined>(
    undefined
  );
  const [verificationRequests, setVerificationRequests] = useState<
    | {
        verifyProfileRequest: boolean | undefined;
        servicesRequest: boolean | undefined;
        reviewRequested: boolean | undefined;
      }
    | undefined
  >();
  const [commentsData, setCommentsData] = useState<SessionComment[]>([]);
  const [filtersCollapsed, setFiltersCollapsed] = useState({
    statusFilter: true,
    requestFilter: true,
  });
  const [selectedSpecialist, setSelectedSpecialist] = useState<
    TypeUserData | undefined
  >();
  const [selectedSpecialityInfo, setSelectedSpecialityInfo] = useState<
    SpecialistData | undefined
  >();
  const [selectNavigationElement, setSelectNavigationElement] = useState<
    "info" | "complaints" | "settings" | "services"
  >("info");

  const [avatars, setAvatars] = useState<{ [userId: string]: string }>({});
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isUpdatedProfileModalOpen, setIsUpdatedProfileModalOpen] =
    useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [filterUpdateCounter, setFilterUpdateCounter] = useState(0);

  const handleClickOutside = (event: any) => {
    const isInsideFilterMenu =
      filterRef.current && filterRef.current.contains(event.target);
    const isInsideSelect = event.target.closest(".multi-select-menu");
    const isInsideSelect2 = event.target.closest(".MuiModal-backdrop");

    if (
      !isInsideFilterMenu &&
      !isInsideSelect &&
      !isInsideSelect2 &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setIsFilterOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && specialistData && specialistData.length > 0) {
        /* socket.off(chat.newDialogMessage, handleMessage);
        socket.on(chat.newDialogMessage, handleMessage); */

        const promises = specialistData.map((spec) =>
          UsersApi.getUserPublicAvatarAndName(spec._id)
        );
        const responses = await Promise.all(promises);
        const avatarsData = responses.reduce((acc, response) => {
          if (response.avatar) {
            acc[response._id] = response.avatar;
          }
          return acc;
        }, {} as { [userId: string]: string });

        setAvatars(avatarsData);
      }
    };
    makeAsync();
  }, [specialistData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData) {
        const payload = {
          userId: userData._id,
          filters: {
            name: search.length > 0 ? search : "",
            email: search.length > 0 ? search : "",
            country:
              selectedCountry && selectedCountry.title
                ? [selectedCountry.title]
                : undefined,
            city:
              selectedCity && selectedCity.length ? selectedCity : undefined,
            gender: genderFilterValue.length ? genderFilterValue : undefined,
            preferredLanguages: selectedLangs.length
              ? selectedLangs
              : undefined,
            specialistId: {
              subSpecializations:
                specializations.selectedSubSpec &&
                specializations.selectedSubSpec.length
                  ? specializations.selectedSubSpec
                  : undefined,
              mainSpecializations:
                specializations.selectedMainSpec &&
                specializations.selectedMainSpec.length
                  ? specializations.selectedMainSpec
                  : undefined,

              isVerified: verifiedStatus,
              isVerifiedChanges:
                verificationRequests === undefined ||
                verificationRequests.verifyProfileRequest === undefined
                  ? undefined
                  : !verificationRequests.verifyProfileRequest,
              servicesConfig:
                verificationRequests === undefined ||
                verificationRequests.servicesRequest === undefined
                  ? undefined
                  : {
                      reviewRequested: verificationRequests.servicesRequest,
                    },
              reviewRequested:
                verificationRequests === undefined ||
                verificationRequests.reviewRequested === undefined
                  ? undefined
                  : verificationRequests.reviewRequested,
            },
            page: page + 1,
            limit: rowsPerPage,
            sort: {
              field: "_id",
              type: -1,
            },
          },
        };
        setIsLoader(true);

        const response = await SpecialistApi.getFilteredSpecialists(
          token,
          payload
        );

        if (response.paramsAvailable && response.paramsAvailable.totalAmount) {
          setTotalAmount(response.paramsAvailable.totalAmount);
        }
        if (response.users && !response.users.length) {
          setSpecialistData(undefined);
        }
        if (response.status && response.users) {
          setSpecialistData(response.users);
        }
        setIsLoader(false);
        if (response.users && !response.users.length) return;
        const [
          genders,
          cities,
          countriesAvailable,
          mainSpecs,
          subSpecs,
          languages,
          age,
          price,
        ] = await getAvailableParams(token, payload.filters);

        if (
          removeFilterElements(
            getExtraElements(selectedLangs as string[], languages),
            selectedLangs
          ).length !== selectedLangs.length
        ) {
          setSelectedLangs(
            removeFilterElements(
              getExtraElements(selectedLangs as string[], languages),
              selectedLangs
            ) as string[]
          );
        }
        if (
          removeFilterElements(
            getExtraElements(genderFilterValue as string[], genders),
            genderFilterValue as string[]
          ).length !== genderFilterValue.length
        ) {
          setGenderFilterValue(
            removeFilterElements(
              getExtraElements(genderFilterValue as string[], genders),
              genderFilterValue as string[]
            ) as string[]
          );
        }

        const responseLangList = [
          ...new Set(
            languages.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setLanguagesList(responseLangList);
        const responseGenderList = [
          ...new Set(
            genders.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setAvailableGenders(responseGenderList);

        const availableMainSpecialization = [
          ...new Set(
            mainSpecs.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setSpecializations((prev) =>
          prev
            ? { ...prev, mainSpecData: availableMainSpecialization }
            : { mainSpecData: availableMainSpecialization }
        );

        const availableSubSpecialization = [
          ...new Set(
            subSpecs.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];

        setSpecializations((prev) =>
          prev
            ? { ...prev, subSpecData: availableSubSpecialization }
            : {
                mainSpecData: availableSubSpecialization,
                subSpecData: availableMainSpecialization,
              }
        );

        const responseAvailableCountry = [
          ...new Set(
            countriesAvailable.filter((item) => !item.includes("undefined"))
          ),
        ];
        setAvailableCountry(
          responseAvailableCountry.map(
            (item) => countries.getAlpha2Code(item, "en") as string
          )
        );
        const responseAvailableCities = [
          ...new Set(
            cities
              .filter((item) => !item.includes("undefined"))
              .map((item, index) => ({
                id: index + 1,
                lable: item,
              }))
          ),
        ];

        setAvailableCity(responseAvailableCities);
        setFilterRanges({
          age: {
            //@ts-expect-error
            min: age.min,
            //@ts-expect-error
            max: age.max,
          },
          price: {
            //@ts-expect-error
            min: price.min,
            //@ts-expect-error
            max: price.max,
          },
        });
      }
    };
    makeAsync();
  }, [filterUpdateCounter, page, rowsPerPage]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setFilterUpdateCounter((prev) => prev + 1);
      setPage(0);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setDebouncedSearch(value);
  };
  const selectSpecialistHandler = (specialistId: string) => {
    if (!specialistData) return;
    const selectedSpec = specialistData.filter(
      (item) => item._id === specialistId
    )[0];
    setSelectedSpecialist(selectedSpec);
  };

  useEffect(() => {
    if (!token || !selectedSpecialist) return;
    if (
      !selectedSpecialityInfo ||
      (selectedSpecialityInfo &&
        !selectedSpecialist
          .specialistIds!.map((item) => item._id)
          .includes(selectedSpecialityInfo._id))
    ) {
      setSelectedSpecialityInfo(selectedSpecialist.specialistIds![0]);
    }
    const makeAsync = async () => {
      const commentsResponse = await SpecialistApi.getSpecialistsComments(
        selectedSpecialist._id,
        100,
        1,
        selectedSpecialityInfo?._id
      );

      if (commentsResponse.status)
        setCommentsData(
          commentsResponse.comments.filter(
            (el) => el.customComment.length || el.defaultComments.length
          )
        );
    };
    makeAsync();
  }, [selectedSpecialist]);

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <div className={s.pageInfoTitleBlock}>
          <h1>Specialists</h1>
          {/*       <span>84% verified user profiles</span> */}
        </div>
      </div>
      <div className={s.filterBlock}>
        <div className={s.searchInputBlock}>
          <input
            type="text"
            className={s.searchInput}
            placeholder="Search..."
            value={search}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <img src={MagnifierIcon} alt="" className={s.magnifierIcon} />
        </div>
        <div className={s.filterButton} ref={filterRef}>
          <div
            className={isFilterOpen ? s.rectangle : s.circle}
            onClick={() => setIsFilterOpen(true)}
          >
            {isFilterOpen ? (
              <div className={s.inputsFilterBlock}>
                <div className={s.columnFilters}>
                  <div className={s.inputFilter}>
                    <ReactFlagsSelect
                      countries={availableCountry}
                      className={s.countrySelect}
                      selected={selectedCountry.code}
                      onSelect={(code) => {
                        setFilterUpdateCounter((prev) => prev + 1);
                        setPage(0);
                        setSelectedCountry({
                          code: code,
                          title: countries.getName(code, "en") as string,
                        });
                      }}
                      searchable
                      placeholder={
                        selectedCountry.code.length ? "" : "Select country"
                      }
                    />
                    <span
                      className={s.closeIconBlock}
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilterUpdateCounter((prev) => prev + 1);
                        setPage(0);
                        setSelectedCountry({ code: "", title: "" });
                      }}
                    >
                      <CloseIcon className={s.closeIcon} />
                    </span>
                  </div>
                  <div className={s.inputFilter}>
                    {availableCity && (
                      <MultipleSelect
                        data={availableCity.map((item) => ({
                          value: item.lable,
                          lable: item.lable,
                        }))}
                        setValue={(value) => {
                          setFilterUpdateCounter((prev) => prev + 1);
                          setPage(0);
                          setSelectedCity(value);
                        }}
                        value={selectedCity}
                        multiplie={true}
                        lable={"City"}
                        lableColor="red"
                        disabled={
                          selectedCountry &&
                          selectedCountry.code !== "" &&
                          availableCity.length
                            ? false
                            : true
                        }
                      />
                    )}
                  </div>
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={languagesList}
                      setValue={(value) => {
                        setFilterUpdateCounter((prev) => prev + 1);
                        setPage(0);
                        typeof value === "string"
                          ? setSelectedLangs([value])
                          : setSelectedLangs(value);
                      }}
                      value={selectedLangs}
                      multiplie={true}
                      lable={"Language"}
                      lableColor="red"
                    />
                  </div>
                </div>
                <div className={s.columnFilters}>
                  {specializations && specializations.mainSpecData && (
                    <div className={s.inputFilter}>
                      <MultipleSelect
                        data={
                          specializations.mainSpecData
                            ? specializations.mainSpecData
                            : []
                        }
                        setValue={(value) => {
                          setFilterUpdateCounter((prev) => prev + 1);
                          setPage(0);
                          setSpecializations((prev) => ({
                            ...prev,
                            selectedMainSpec: value,
                            selectedSubSpec: [],
                          }));
                        }}
                        value={
                          specializations.selectedMainSpec
                            ? specializations.selectedMainSpec
                            : []
                        }
                        multiplie={true}
                        lable={"Main specialization"}
                        lableColor="red"
                      />{" "}
                    </div>
                  )}
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={
                        specializations.subSpecData
                          ? specializations.subSpecData
                          : []
                      }
                      setValue={(value) => {
                        setFilterUpdateCounter((prev) => prev + 1);
                        setPage(0);
                        setSpecializations((prev) => ({
                          ...prev,
                          selectedSubSpec: value,
                        }));
                      }}
                      value={
                        specializations.selectedSubSpec
                          ? specializations.selectedSubSpec
                          : []
                      }
                      multiplie={true}
                      lable={"Sub specialization"}
                      lableColor="red"
                      disabled={
                        specializations.selectedMainSpec &&
                        specializations.selectedMainSpec.length
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={
                        availableGenders ? availableGenders : genderSelectData
                      }
                      setValue={(value) => {
                        setFilterUpdateCounter((prev) => prev + 1);
                        setPage(0);
                        setGenderFilterValue(value);
                      }}
                      value={genderFilterValue}
                      multiplie={true}
                      lable={"Gender"}
                      lableColor="red"
                    />{" "}
                  </div>
                </div>
              </div>
            ) : (
              <FilterIcon className={s.filterIcon} />
            )}
          </div>
        </div>
        <div className={s.leftFiltersBlock}>
          <div style={{ zIndex: 99 }}>
            <span>Verification requests</span>
            <div className={s.verifiedStatusFilterBlock}>
              <div
                className={s.selectedStatusBlock}
                onClick={(e) => {
                  e.stopPropagation();
                  setFiltersCollapsed((prev) => ({
                    ...prev,
                    requestFilter: !prev.requestFilter,
                  }));
                }}
                style={
                  filtersCollapsed.requestFilter
                    ? {}
                    : {
                        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                        paddingBottom: "3px",
                      }
                }
              >
                <span>
                  {verificationRequests === undefined
                    ? "All"
                    : verificationRequests.servicesRequest === true
                    ? "Services request"
                    : verificationRequests.reviewRequested
                    ? "Ready for review"
                    : "Unverified changes"}
                </span>{" "}
                <ChevronDownIcon
                  className={
                    filtersCollapsed.requestFilter
                      ? s.chevronDownIcon
                      : s.chevronUpIcon
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setFiltersCollapsed((prev) => ({
                      ...prev,
                      requestFilter: !prev.requestFilter,
                    }));
                  }}
                />
              </div>
              <AnimateHeight
                height={filtersCollapsed.requestFilter ? 0 : "auto"}
                duration={500}
                className={s.animHeight}
              >
                {statusRequestSelectedData.map((item, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setFilterUpdateCounter((prev) => prev + 1);
                      setPage(0);
                      setVerificationRequests(item.value);
                      setFiltersCollapsed((prev) => ({
                        ...prev,
                        requestFilter: !prev.requestFilter,
                      }));
                    }}
                  >
                    {item.lable}
                  </span>
                ))}
              </AnimateHeight>
            </div>
          </div>
          <div style={{ zIndex: 99 }}>
            <span>Status</span>
            <div className={s.verifiedStatusFilterBlock}>
              <div
                className={s.selectedStatusBlock}
                onClick={(e) => {
                  e.stopPropagation();
                  setFiltersCollapsed((prev) => ({
                    ...prev,
                    statusFilter: !prev.statusFilter,
                  }));
                }}
                style={
                  filtersCollapsed.statusFilter
                    ? {}
                    : {
                        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                        paddingBottom: "3px",
                      }
                }
              >
                <span>
                  {verifiedStatus === undefined
                    ? "All"
                    : verifiedStatus === false
                    ? "Unverified"
                    : "Verified"}
                </span>{" "}
                <ChevronDownIcon
                  className={
                    filtersCollapsed.statusFilter
                      ? s.chevronDownIcon
                      : s.chevronUpIcon
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setFiltersCollapsed((prev) => ({
                      ...prev,
                      statusFilter: !prev.statusFilter,
                    }));
                  }}
                />
              </div>
              <AnimateHeight
                height={filtersCollapsed.statusFilter ? 0 : "auto"}
                duration={500}
                className={s.animHeight}
              >
                {statusSelectData.map((item, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setFilterUpdateCounter((prev) => prev + 1);
                      setPage(0);
                      setVerifiedStatus(item.value);
                      setFiltersCollapsed((prev) => ({
                        ...prev,
                        statusFilter: !prev.statusFilter,
                      }));
                    }}
                  >
                    {item.lable}
                  </span>
                ))}
              </AnimateHeight>
            </div>
          </div>
        </div>
      </div>
      <div className={s.contentBlock}>
        <div className={s.tableBlock}>
          {specialistData && specialistData.length ? (
            <SpecialistTable
              rows={specialistData.map((item, index) => ({
                id: item._id,
                name:
                  getTranslation(item.name) +
                  " " +
                  getTranslation(item.surname),
                email: item.email,
                specialization: item.specialistIds!.map(
                  (item) => item.mainSpecializations[0]?.specialization
                ),
                status: item.specialistIds![0].isVerified,
                reviewRequested: item
                  .specialistIds!.map((spec) => spec.reviewRequested)
                  .includes(true),
                serviceReviewRequested: item
                  .specialistIds!.map(
                    (item) => item.servicesConfig.reviewRequested
                  )
                  .includes(true),
              }))}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              selectSpecialistHandler={selectSpecialistHandler}
              count={totalAmount}
            />
          ) : isLoader ? (
            <div className={s.noSpecialistBlock}>
              <Loader size={80} />{" "}
            </div>
          ) : (
            <div className={s.noSpecialistBlock}>
              <h1>No specialists found</h1>
            </div>
          )}
        </div>
        {specialistData && specialistData.length ? (
          <div
            className={`${s.specialistInfoBlock} ${
              selectedSpecialist && s.specialistInfoBlockActive
            }`}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={s.backBlock}
                role="button"
                onClick={() => setSelectedSpecialist(undefined)}
              >
                <ChevronLeftIcon className={s.chevronBackIcon} />
                Back
              </div>
              {selectedSpecialist && (
                <Link
                  to={`/chat?userIdForCreate=${selectedSpecialist._id}`}
                  className={s.sendMessageBtn}
                >
                  Send message
                </Link>
                /*  <StandartButton
                  buttonTitle="Send message"
                  action={() => {
                    navigate("/chat", {
                      state: {
                        userIdForCreate: selectedSpecialist._id,
                      },
                    });
                  }}
                /> */
              )}
            </div>

            <div className={s.headNameBlock}>
              {selectedSpecialist && (
                <CircleAvatar
                  /*  userId={selectedChat.members._id} */
                  name={getTranslation(selectedSpecialist?.name)}
                  surname={getTranslation(selectedSpecialist?.surname)}
                  avatarProps={avatars[selectedSpecialist._id]}
                />
              )}
              <span>
                {getTranslation(selectedSpecialist?.name) +
                  " " +
                  getTranslation(selectedSpecialist?.surname)}
              </span>
              {selectedSpecialityInfo?.isVerified && <VerifyIcon />}
            </div>

            {selectedSpecialist &&
              selectedSpecialist.specialistIds!.length > 1 && (
                <div className={s.specialityTabsBlock}>
                  {selectedSpecialist.specialistIds?.map((item, index) => (
                    <div
                      className={
                        selectedSpecialityInfo?._id === item._id
                          ? s.activeChooseSpecTab
                          : s.chooseSpecTab
                      }
                      onClick={() => setSelectedSpecialityInfo(item)}
                    >
                      {item.mainSpecializations[0].specialization}
                      {(!item.isVerifiedChanges ||
                        item.servicesConfig.reviewRequested) && (
                        <AllertCircle width={20} height={20} />
                      )}
                    </div>
                  ))}
                </div>
              )}

            <div className={s.navigateSpecInfoBlock}>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "info" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("info")}
              >
                Info
              </div>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "complaints" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("complaints")}
              >
                Complaints
              </div>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "settings" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("settings")}
              >
                Settings
                {!selectedSpecialityInfo?.isVerifiedChanges && (
                  <Tooltip title="Unverified profile changes" arrow>
                    <AllertCircle
                      className={s.allowedIcon}
                      width={20}
                      height={20}
                    />
                  </Tooltip>
                )}
              </div>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "services" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("services")}
              >
                Services
                {selectedSpecialityInfo?.servicesConfig.reviewRequested && (
                  <Tooltip title="Request for review" arrow>
                    <AllertCircle
                      className={s.allowedIcon}
                      width={20}
                      height={20}
                    />
                  </Tooltip>
                )}
              </div>
              <div className={s.underLine}></div>
            </div>
            {selectNavigationElement === "info" ? (
              <div className={s.mainSpecInfoBlock}>
                <div className={s.personInfoTitleBlock}>
                  <h2 className={s.personInfoTitle}>Personal Information</h2>
                  <div
                    onClick={() => setIsProfileModalOpen((prev) => !prev)}
                    role="button"
                    className={s.viewAllBtn}
                  >
                    View all
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.infoElement}>
                    <span>Verified</span>
                    <span>
                      {selectedSpecialityInfo?.isVerified ? "Yes" : "No"}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>First Name</span>
                    <span>{getTranslation(selectedSpecialist?.name)}</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Last Name</span>
                    <span>{getTranslation(selectedSpecialist?.surname)}</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Email</span>
                    <span>{selectedSpecialist?.email}</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Specialization</span>
                    <span>
                      {selectedSpecialityInfo?.mainSpecializations[0] &&
                        selectedSpecialityInfo?.mainSpecializations[0]
                          .specialization}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>State</span>
                    <span>
                      {selectedSpecialist?.location?.includes("undefined")
                        ? "-"
                        : selectedSpecialist?.location}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Age</span>
                    <span>
                      {calculateAge(new Date(selectedSpecialist?.dateOfBirth!))}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Practice hours</span>
                    <span>{selectedSpecialityInfo?.practiceHours}+</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Years experience</span>
                    <span>{selectedSpecialityInfo?.yearsOfExperience}</span>
                  </div>
                </div>
              </div>
            ) : selectNavigationElement === "complaints" ? (
              <div className={s.complaintsBlock}>
                {commentsData && commentsData.length ? (
                  commentsData.map((item, index) => (
                    <CommentCard
                      id={item.commentBy}
                      commentMessage={item.customComment}
                      staticComments={item.defaultComments}
                      date={new Date(item.date).toISOString()}
                    />
                  ))
                ) : (
                  <h2>No Complaints</h2>
                )}
              </div>
            ) : selectNavigationElement === "services" ? (
              selectedSpecialityInfo && (
                <ServicesConfigs
                  specialistData={selectedSpecialityInfo}
                  setSpecialistData={setSpecialistData}
                  setSeletedSpecialityInfo={setSelectedSpecialityInfo}
                  setSelectedSpecialist={setSelectedSpecialist}
                />
              )
            ) : (
              selectedSpecialist && (
                <SpecialistSettings
                  setSeletedSpecialityInfo={setSelectedSpecialityInfo}
                  selectedSpecialist={selectedSpecialityInfo}
                  setIsUpdatedProfileModalOpen={setIsUpdatedProfileModalOpen}
                  setSelectedSpecialist={setSelectedSpecialist}
                  setSpecialistData={setSpecialistData}
                  getPublicInfoString={`${selectedSpecialist.latinName}-${
                    selectedSpecialist.latinSurname
                  }-${selectedSpecialist._id.slice(-7)}`}
                  userId={selectedSpecialist._id}
                  setIsPublicProfileModalOpen={setIsProfileModalOpen}
                />
              )
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <ModalWindow
        isOpen={isProfileModalOpen}
        setIsOpen={setIsProfileModalOpen}
        height="90vh"
        width="90vw"
        padding="0 20px"
        /*  bgColor="rgb(46,46,46)" */
      >
        <div className={s.profileContainer}>
          {selectedSpecialist && (
            <Profile
              userId={selectedSpecialist._id}
              isOpen={isProfileModalOpen}
              setIsOpen={setIsProfileModalOpen}
              getPublicInfoString={`${selectedSpecialist.latinName}-${
                selectedSpecialist.latinSurname
              }-${selectedSpecialist._id.slice(-7)}`}
              isPublicProfile
            />
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={isUpdatedProfileModalOpen}
        setIsOpen={setIsUpdatedProfileModalOpen}
        height="90vh"
        width="90vw"
        padding="0 20px"
        /*  bgColor="rgb(46,46,46)" */
      >
        <div className={s.profileContainer}>
          {selectedSpecialist && (
            <Profile
              userId={selectedSpecialist._id}
              isOpen={isUpdatedProfileModalOpen}
              setIsOpen={setIsUpdatedProfileModalOpen}
              getPublicInfoString={`${selectedSpecialist.latinName}-${
                selectedSpecialist.latinSurname
              }-${selectedSpecialist._id.slice(-7)}`}
              isPublicProfile={false}
              initialSpecialization={selectedSpecialist._id}
            />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default Specialists;
