import React, { useContext } from "react";
import {
  Menu,
  menuClasses,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  SubMenu,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../../assets/SideBar/logout.svg";
import { sidebarMenu } from "../../constans/sideBar";
import { ContextProvider } from "../../contextProvider";
import { logout } from "../../utils/general";
import { theme } from "../../utils/theme";
import Burger from "../Burger/Burger";
import "./SideBar.css";
import s from "./SideBar.module.css";

type Theme = "light" | "dark";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#242424",
      color: "white",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "white",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const SideBar = () => {
  const { userData } = useContext(ContextProvider);
  const isUserCompany = localStorage.getItem("haveCompany");
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(true);
  const [theme, setTheme] = React.useState<Theme>("dark");

  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      [`&.${menuClasses.active}`]: {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 1 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
      "&:hover": {
        backgroundColor: "#3fb082",
        color: "white",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      className={s.sidebarContainer}
      style={{
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar
        className={!collapsed ? s.sideBar : s.sideBarClose}
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        rtl={rtl}
        breakPoint="md"
        backgroundColor={"#242424"}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div className={s.sidebarContent}>
          <div className={s.logoBlock}>
            {/*   {!collapsed && <img src={Logo} alt="LOGO" />} */}
            <Burger action={setCollapsed} value={collapsed} />
          </div>

          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu menuItemStyles={menuItemStyles}>
              {sidebarMenu.map((item, index) =>
                item.hidden ? (
                  <span></span>
                ) : (
                  <div
                    key={index}
                    className={
                      location.pathname === item.pathname
                        ? s.menuItemSelect
                        : s.menuItem
                    }
                  >
                    {item.submenu ? (
                      <div style={{ position: "relative" }}>
                        <span
                          style={{
                            opacity:
                              location.pathname === "/company-dashboard" ||
                              location.pathname === "/company-programs" ||
                              location.pathname === "/company-employers"
                                ? 1
                                : 0,
                            position: "absolute",
                            top: "5px",
                          }}
                          className={
                            collapsed
                              ? s.collapsedMenuLeftBlock
                              : s.menuLeftBlock
                          }
                        ></span>
                        {item.title !== "Company" ||
                          (isUserCompany && (
                            <SubMenu
                              className={s.subMenu}
                              label={!collapsed ? item.title : ""}
                              style={{
                                width: collapsed ? "75%" : "122%",
                                borderRadius: "5px",
                                marginLeft: collapsed
                                  ? "1rem"
                                  : "calc(10px + 1rem)",
                                paddingLeft:
                                  collapsed &&
                                  (location.pathname === "/company-dashboard" ||
                                    location.pathname === "/company-programs" ||
                                    location.pathname === "/company-employers")
                                    ? "5px"
                                    : collapsed
                                    ? "6px"
                                    : "15px",
                                paddingRight:
                                  collapsed &&
                                  (location.pathname === "/company-dashboard" ||
                                    location.pathname === "/company-programs" ||
                                    location.pathname === "/company-employers")
                                    ? 0
                                    : "20px",
                              }}
                              key={index}
                              data-testid={item.dataTestId}
                              active={
                                location.pathname === "/company-dashboard" ||
                                location.pathname === "/company-programs" ||
                                location.pathname === "/company-employers"
                                  ? true
                                  : false
                              }
                              icon={
                                <div className={s.block}>
                                  <item.icon
                                    className={
                                      location.pathname ===
                                        "/company-dashboard" ||
                                      location.pathname ===
                                        "/company-programs" ||
                                      location.pathname === "/company-employers"
                                        ? s.subMenuIconSelect
                                        : s.subMenuIcon
                                    }
                                  />
                                </div>
                              }
                              onClick={
                                !collapsed
                                  ? undefined
                                  : (e) => e.stopPropagation()
                              }
                            >
                              {item.submenuItems &&
                                item.submenuItems.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    component={<Link to={item.path} />}
                                    className={
                                      location.pathname === item.path
                                        ? s.subMenuItemSelected
                                        : s.submenuItem
                                    }
                                  >
                                    {!collapsed && item.title}
                                  </MenuItem>
                                ))}
                            </SubMenu>
                          ))}
                      </div>
                    ) : (
                      <>
                        <span
                          style={{
                            opacity:
                              location.pathname === item.pathname ? 1 : 0,
                          }}
                          className={
                            collapsed
                              ? s.collapsedMenuLeftBlock
                              : s.menuLeftBlock
                          }
                        ></span>
                        <MenuItem
                          style={{
                            width: collapsed ? "75%" : "90%",
                            height: "40px",
                            borderRadius: "5px",
                            paddingLeft:
                              collapsed && location.pathname === item.pathname
                                ? "5px"
                                : "15px",
                            paddingRight:
                              collapsed && location.pathname === item.pathname
                                ? 0
                                : "20px",
                          }}
                          key={index}
                          data-testid={item.dataTestId}
                          active={
                            location.pathname === item.pathname ? true : false
                          }
                          component={<Link to={item.pathname} />}
                          icon={
                            <div className={s.block}>
                              <item.icon
                                className={`${s.menuIcon}  ${
                                  item.fillStyleIcon ? s.fillMenuIcon : ""
                                }`}
                              />
                            </div>
                          }
                          className={s.menuLink}
                        >
                          {!collapsed && item.title}
                        </MenuItem>
                      </>
                    )}
                  </div>
                )
              )}
            </Menu>
          </div>
          <div
            className={
              collapsed ? s.bottomButtonsBlockCollapsed : s.bottomButtonsBlock
            }
          >
            <button
              className={s.logoutButton}
              onClick={() => logout(userData?._id!)}
            >
              <LogoutIcon className={s.logoutButtonIcon} />
              {!collapsed && <p>Log out</p>}
            </button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
