import { Skeleton, Tooltip } from "@mui/material";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { useContext, useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as EditIcon } from "../../../../assets/Certificates/edit.svg";
import EmptyCertificate from "../../../../assets/Profile/specialist/emptyCertificate.png";
import BlurImageEditor from "../../../../components/BlurImageEditor/BlurImageEditor";
import BlurPDFEditor from "../../../../components/BlurPdfEditor/BlurPdfEditor";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../../contextProvider";
import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
  SpecialistData,
} from "../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../utils/dateActions";
import { getPdfThumbnail } from "../../../../utils/filesAction";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import UpdateCertificatesModal from "../AddCertificatesModal/UpdateCertificatesModal";
import s from "../EducationSection.module.css";

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type EducationCardProps = {
  educationData: EducationCertificatesResponse;
  previewFileHandler: (link: string, type: string) => void;
  specialistId: string;
  specialistData: SpecialistData;
  setCertificatesData: React.Dispatch<React.SetStateAction<CertificatesData>>;
};

const EducationCard = ({
  educationData,
  previewFileHandler,
  specialistId,
  specialistData,
  setCertificatesData,
}: EducationCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [certificateThumbnail, setCertificateThumbnail] = useState<
    string | undefined
  >();
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [imageEditorModal, setImageEditorModal] = useState(false);
  const [certificateLink, setCertificateLink] = useState(educationData.link);
  const [educationModal, setEducationModal] = useState(false);

  useEffect(() => {
    setCertificateLink(educationData.link);
  }, [educationData.link]);

  useEffect(() => {
    if (!certificateLink) return;
    const loadThumbnail = async () => {
      if (educationData.type === "pdf") {
        setThumbnailLoader(true);
        const thumbnail = await getPdfThumbnail(certificateLink);
        setCertificateThumbnail(thumbnail);
        setThumbnailLoader(false);
      } else {
        setCertificateThumbnail(certificateLink);
      }
    };

    loadThumbnail();
  }, [certificateLink]);

  const updateCertificateFile = async (newFile: File) => {
    if (!token) return;
    const payload = {
      file: newFile,
      specialistId: specialistId,
      updateData: { _id: educationData._id },
    };
    const response = await SpecialistApi.updateEducationCertificate(
      payload,
      token
    );

    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.certificate) {
      setImageEditorModal(false);
      setCertificateLink(response.certificate.link);
      SuccesNotify("Certificate has been updated");
    }
  };

  return (
    <div className={s.certificateElement}>
      {thumbnailLoader ? (
        <Skeleton variant="rounded" width={"100%"} height={200} />
      ) : certificateThumbnail && certificateLink ? (
        <Tooltip arrow title={"Show certificate"}>
          <div
            onClick={() =>
              previewFileHandler(certificateLink, educationData.type)
            }
            className={s.showCertificateBtn}
          >
            <img
              src={certificateThumbnail}
              alt="PDF Thumbnail"
              className={s.thumbnailCertificate}
            />
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow title={"In the process of learning"}>
          <div className={s.emptyCertificateBlock}>
            <img
              src={EmptyCertificate}
              alt=""
              className={s.emtyCertificateImg}
            />
            <div className={s.blurBlock}></div>
          </div>
        </Tooltip>
      )}
      <div className={s.mainInfoCertificateBlock}>
        <div className={s.headCertificateBlock}>
          <div>
            <span>
              {educationData.education.text.find(
                (el) => el.language === userData?.selectedLanguage
              )?.text ?? educationData.education.text[0].text}
            </span>
            <span className={s.educationDescription}>
              {educationData.educationCourse.text.find(
                (el) => el.language === userData?.selectedLanguage
              )?.text ?? educationData.educationCourse.text[0].text}
            </span>
          </div>
        </div>
        {educationData.subSpecializations &&
          educationData.subSpecializations.length > 0 && (
            <div className={s.subspecializationsBlock}>
              {educationData.subSpecializations.map((item, index) => (
                <span key={index}>
                  {
                    item.labels.find(
                      (el) => el.language === userData?.selectedLanguage
                    )?.text
                  }
                </span>
              ))}
            </div>
          )}
        {educationData.studyHours ? (
          <div>Hours of study: {educationData.studyHours + " " + "hours"}</div>
        ) : null}
        {educationData.educationAccreditation && (
          <div>
            {"Accreditation: " +
              (educationData.educationAccreditation.text.find(
                (el) => el.language === "en"
              )?.text ?? educationData.educationAccreditation.text[0].text)}
          </div>
        )}
        <div className={s.bottomCertificateBlock}>
          {educationData.membershipStart && educationData.membershipEnd && (
            <span>
              {formatEducationPeriod(
                new Date(educationData.membershipStart),
                new Date(educationData.membershipEnd),
                "en"
              )}
            </span>
          )}
        </div>
        <div className={s.actionBlock}>
          {educationData.link ? (
            <StandartButton
              action={() => setImageEditorModal(true)}
              buttonTitle="Blur surname"
              width="calc(100% - 62px)"
            />
          ) : (
            <div></div>
          )}
          <div
            role="button"
            className={s.editBtn}
            onClick={() => setEducationModal(true)}
          >
            <EditIcon />
          </div>
        </div>
      </div>
      {educationData.link && (
        <ModalWindow
          isOpen={imageEditorModal}
          setIsOpen={setImageEditorModal}
          width="fit-content"
        >
          {educationData.type !== "pdf" ? (
            <BlurImageEditor
              imageUrl={educationData.link}
              saveImage={updateCertificateFile}
            />
          ) : (
            <BlurPDFEditor
              pdfUrl={educationData.link}
              savePdf={updateCertificateFile}
            />
          )}
        </ModalWindow>
      )}
      <UpdateCertificatesModal
        certificateType="education"
        modalOpen={educationModal}
        setModalOpen={setEducationModal}
        specialistId={specialistData?._id!}
        setNewCertificate={setCertificatesData}
        mainSpec={specialistData?.mainSpecializations[0]._id!}
        educationToUpdate={educationData}
      />
    </div>
  );
};

export default EducationCard;
