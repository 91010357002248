import { ISO639Lang } from "../../../constans/languagesList";
import { DiscountTypes } from "../../../types/TypeSpecialist";
import styles from "./SessionPrices.module.css";

type Price = {
  language: string;
  amount: number;
};

type SessionPricesProps = {
  prices: Price[];
  discountType?: DiscountTypes;
};

const calculateSavings = (oldAmount: number, amount: number) =>
  oldAmount - amount;

const SessionPrices = ({ prices }: SessionPricesProps) => {
  return (
    <div className={styles.priceBlock}>
      <div className={styles.priceMultiple}>
        {prices.map((price, index) => (
          <div
            className={
              prices.length > 4 ? styles.wrapPriceitem : styles.priceItem
            }
            key={index}
          >
            <div className={styles.language}>{ISO639Lang[price.language]}</div>
            <div className={styles.price}>${price.amount}</div>
            {/*  {price.oldAmount && price.oldAmount !== price.amount && (
              <div>
                <span className={styles.oldPrice}>${price.oldAmount}</span>
                <span className={styles.savings}>
                  {" "}
                  Save ${calculateSavings(price.oldAmount, price.amount)}
                </span>
              </div>
            )}
            {price.note && <div className={styles.priceNote}>{price.note}</div>} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionPrices;
